import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {Box, Button, Divider, IconButton, Paper, Stack, Tooltip} from "@mui/material";
import {AddRounded, DeleteForeverRounded, EditRounded} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CustomPagination from "../crud/CustomPagination";
import CustomSearch from "../crud/CustomSearch";
import {useSnackbar} from "notistack";
import {buildParams} from "../../utils/pageable";
import moment from "moment";
import ConfirmDialog from "../common/ConfirmDialog";
import {useEffect, useState} from "react";

export default function OrganizationsList() {

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(true);
    const deleteDialogState = useState(false);
    const [organizationId, setOrganizationId] = useState(null);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 25,
        field: "",
        sort: "asc",
        filter: "",
        rows: [],
        rowCount: 0,
        loading: false,
    });

    async function deleteOrganization() {
        if (organizationId === null) {
            return;
        }
        await axios.delete(`/api/organizations/${organizationId}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
            setRefresh(!refresh);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
        setOrganizationId(null);
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Nom',
            description: "Nom de la salle",
            minWidth: 200,
            flex: 1
        },
        {
            field: 'address',
            headerName: 'Adresse',
            sortable: false,
            minWidth: 250,
            flex: 1,
            renderCell: (params) => {
                return <Stack spacing={0}>
                    <span>{params.row.address1}</span>
                    {params.row.address2 && <span>{params.row.address2}</span>}
                    <span>{params.row.zipCode} {params.row.city}</span>
                </Stack>
            }
        },
        {
            field: 'memberCount',
            headerName: 'Utilisateurs',
            minWidth: 95,
            maxWidth: 95,
        },
        {
            field: 'lastModifiedDate',
            headerName: 'Dernière modification',
            minWidth: 250,
            flex: 1,
            valueGetter: (params) => ({
                date: params.row.lastModifiedAt ?? null,
                name: params.row.lastModifiedBy ?? null
            }),
            renderCell: (params) => {
                return <Stack>
                    <span>{
                        params.value.date && (params.value.name
                            ? params.value.name
                            : "Système")
                    }</span>
                    <span>{
                        params.value.date
                            ? <small>{moment(params.value.date).format("Do MMMM YYYY à HH:mm:ss")}</small>
                            : "Jamais"
                    }</span>
                </Stack>;
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            minWidth: 100,
            maxWidth: 100,
            getActions: (params) => [
                <Tooltip title={"Éditer"}>
                    <IconButton size={"small"} color={"warning"} onClick={() => navigate(`${params.id}/edit`)}>
                        <EditRounded fontSize="small"/>
                    </IconButton>
                </Tooltip>,
                <Tooltip title={"Supprimer"}>
                    <IconButton size={"small"} color={"error"} onClick={() => {
                        setOrganizationId(params.row.id);
                        deleteDialogState[1](true);
                    }}>
                        <DeleteForeverRounded fontSize="small"/>
                    </IconButton>
                </Tooltip>
            ]
        },
    ];

    useEffect(() => {
        let active = true;

        (async () => {
            setRowsState(prev => ({...prev, loading: true}));
            const params = buildParams(rowsState.page, rowsState.pageSize, rowsState.field, rowsState.sort);
            params.append("filter", rowsState.filter);
            const newRows = await axios.get(`/api/organizations`, {params}).catch(() => {
                enqueueSnackbar("Échec de la récupération des fédérations", {
                    variant: "error",
                    preventDuplicate: true
                });
            });

            if (!active) {
                return;
            }

            if (newRows) {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                    rows: newRows.data.items,
                    rowCount: newRows.data.count
                }));
            } else {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                }));
            }
        })();

        return () => {
            active = false;
        };
    }, [rowsState.page, rowsState.pageSize, rowsState.filter, refresh, rowsState.field, rowsState.sort, enqueueSnackbar]);

    return (
        <Paper sx={{height: "100%", width: '100%'}}>
            <DataGrid
                columns={columns}
                pagination
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) => setRowsState(prev => ({...prev, page}))}
                onPageSizeChange={(pageSize) => setRowsState(prev => ({...prev, pageSize}))}
                sortingMode="server"
                onSortModelChange={(sortModel) => {
                    if (sortModel.length > 0) {
                        setRowsState({...rowsState, sort: sortModel[0].sort, field: sortModel[0].field});
                    } else {
                        setRowsState({...rowsState, sort: "asc", field: ""});
                    }
                }}
                getRowHeight={() => 'auto'}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                }}
                disableSelectionOnClick
                components={{
                    Footer: () => {
                        return (<Box>
                            <Divider/>
                            <Stack direction={"row-reverse"} spacing={2} sx={{p: 2, alignItems: "center"}}>
                                <Button variant={"outlined"} color={"success"} startIcon={<AddRounded/>}
                                        onClick={() => navigate("new")}>
                                    Créer une fédération
                                </Button>
                                <CustomPagination/>
                                <CustomSearch rowsState={rowsState} setRowsState={setRowsState}/>
                            </Stack>
                        </Box>)
                    }
                }}
            />

            <ConfirmDialog
                title={"Supprimer une fédération"}
                acceptText={"Supprimer"}
                visibleState={deleteDialogState}
                acceptPromise={() => deleteOrganization()}>
                Êtes-vous sûr(e) de vouloir supprimer cette fédération ?
            </ConfirmDialog>
        </Paper>
    );
}

import {useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Collapse,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@mui/material";
import axios from "axios";
import {useSnackbar} from "notistack";

const style = {
    submitButton: {
        marginTop: 16
    },
    container: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};

export default function RequestAccess() {

    const {enqueueSnackbar} = useSnackbar();

    const [alertTimeout, setAlertTimeout] = useState(-1);
    const [alertVisible, setAlertVisible] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        organizationId: ""
    });

    const showToast = (title, message, severity) => {
        clearTimeout(alertTimeout);
        setTitle(title);
        setMessage(message);
        setSeverity(severity.toLowerCase());
        setAlertVisible(true);
        if (severity.toLowerCase() === "error") {
            setAlertTimeout(setTimeout(() => setAlertVisible(false), 5000));
        }
    }

    function onSubmit(e) {
        e.preventDefault()
        axios.post("/api/requestAccess", state).then(response => {
            showToast(response.data.title, response.data.message, response.data.severity.toLowerCase());
            setState({
                firstName: "",
                lastName: "",
                phone: "",
                mobile: "",
                fax: "",
                email: "",
                organizationId: ""
            });
        }).catch(error => {
            let response = error.response;
            showToast(response.data.title, response.data.message, response.data.severity.toLowerCase());
        });
    }

    useEffect(() => {
        (async () => {
            axios.get(`/api/organizations/public`).then(response => {
                setOrganizations(response.data.items.sort((a, b) => a.name > b.name));
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des fédérations", {
                    variant: "error",
                    preventDuplicate: true
                });
            })
        })();
    }, [enqueueSnackbar]);

    return (
        <Container maxWidth={"md"} sx={{p: 3}} style={style.container}>
            <Collapse direction={"up"} in={alertVisible} timeout={400}>
                <Alert severity={severity} sx={{marginBottom: 2}} onClose={() => setAlertVisible(false)}>
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
            </Collapse>
            <Paper elevation={3} style={{textAlign: 'center'}}>
                <Box padding={3}>
                    <h1 style={{marginTop: 0}}>Demande d'accès</h1>
                    <p>Vous pouvez faire la demande d’un accès à l’application de réservation des salles.<br/>
                        Votre demande sera rapidement traitée par l’équipe de l’AFUL.</p>

                    <form onSubmit={onSubmit} id={"form"}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField required fullWidth label="Nom" variant="outlined"
                                           onChange={(e) => setState(state => ({
                                               ...state,
                                               lastName: e.target.value
                                           }))} value={state.lastName}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required fullWidth label="Prénom" variant="outlined"
                                           onChange={(e) => setState(state => ({
                                               ...state,
                                               firstName: e.target.value
                                           }))} value={state.firstName}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required fullWidth label="Téléphone" variant="outlined"
                                           onChange={(e) => setState(state => ({
                                               ...state,
                                               phone: e.target.value
                                           }))} value={state.phone}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Portable" variant="outlined"
                                           onChange={(e) => setState(state => ({
                                               ...state,
                                               mobile: e.target.value
                                           }))} value={state.mobile}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Fax" variant="outlined"
                                           onChange={(e) => setState(state => ({
                                               ...state,
                                               fax: e.target.value
                                           }))} value={state.fax}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required fullWidth label="E-mail" type={"email"} variant="outlined"
                                           onChange={(e) => setState(state => ({
                                               ...state,
                                               email: e.target.value.toLowerCase()
                                           }))} value={state.email}/>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'left'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="organizationSelect">Fédération</InputLabel>
                                    <Select required labelId="organizationSelect" label={"Fédération"}
                                            variant="outlined" MenuProps={MenuProps} onChange={e => {
                                        setState(prev => ({...prev, organizationId: e.target.value}))
                                    }} value={state.organizationId}>
                                        <MenuItem value={""}><em>Aucune</em></MenuItem>
                                        {organizations.map(ref => (<MenuItem value={ref.id} key={ref.id}>
                                            {ref.name}
                                        </MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" type={"submit"}>Envoyer la demande</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

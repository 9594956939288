import {Box, Grid, Paper} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
import StartDateField from "./fields/StartDateField";
import EndDateField from "./fields/EndDateField";
import RoomPeriodField from "./fields/RoomPeriodField";
import EventNameField from "./fields/EventNameField";
import RoomSelectField from "./fields/RoomSelectField";
import ParticipantsField from "./fields/ParticipantsField";
import PriceIndicatorField from "./fields/PriceIndicatorField";
import GearField from "./fields/GearField";
import DescriptionField from "./fields/DescriptionField";
import {computeRoomPrice, isAdmin} from "../BookingUtils";
import OrganizationField from "./fields/OrganizationField";
import StatusIndicatorField from "./fields/StatusIndicatorField";
import InternalNotesField from "./fields/InternalNotesField";

export default function BookRoomForm({formState, review, canEdit}) {
    const {enqueueSnackbar} = useSnackbar();

    const [state, setState] = formState;
    const [rooms, setRooms] = useState([])
    const [organizations, setOrganizations] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState(rooms);
    const roomGears = useMemo(() => {
        if (!review) {
            return rooms.filter(room => room.id === state.roomId)
                .flatMap(room => room.gears)
        }
        return state.availableGears;
    }, [state.roomId, state.availableGears, rooms, review]);

    useEffect(() => {
        if (!review) {
            setState(prev => ({
                ...prev,
                price: computeRoomPrice(rooms.find(room => room.id === prev.roomId), prev) +
                    state.gearsId.map(id => roomGears.filter(gear => gear.id === id))
                        .flat()
                        .reduce((acc, curr) => acc + curr.price, 0)
            }));
        }
    }, [state.roomId, state.period, state.startTime, state.endTime, state.gearsId, review, rooms, setState, roomGears])


    useEffect(() => {
        if (!review) {
            axios.get('/api/rooms/meeting', {
                params: {
                    startTime: state.startTime.toISOString(),
                    endTime: state.endTime.toISOString(),
                    period: state.period
                }
            }).then(response => {
                if (!response.data.items.map(room => room.id).includes(state.roomId)) {
                    setState(prev => ({...prev, roomId: ""}));
                }
                setRooms(response.data.items.sort((a, b) => a.name > b.name ? 1 : -1));
                setFilteredRooms(response.data.items.sort((a, b) => a.name > b.name ? 1 : -1));
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des salles", {
                    variant: "error",
                    preventDuplicate: true
                });
            });
        }
    }, [enqueueSnackbar, state.startTime, state.endTime, state.period, state.roomId, setState, review]);

    useEffect(() => {
        if (isAdmin()) {
            axios.get('/api/organizations/public')
                .then(response => {
                    setOrganizations(response.data.items.toSorted((a, b) => a.name > b.name ? 1 : -1));
                })
                .catch(() => {
                    enqueueSnackbar("Échec de la récupération des fédérations", {
                        variant: "error",
                        preventDuplicate: true
                    });
                });
        }
    }, [enqueueSnackbar])

    function filterRooms(capacity) {
        if (capacity === "") {
            setFilteredRooms(rooms);
            return;
        }
        let newRooms = rooms.filter(room => room.capacity >= capacity)
        setFilteredRooms(newRooms);
        if (!newRooms.map(room => room.id).includes(state.roomId)) {
            setState(prev => ({...prev, roomId: ""}));
        }
    }

    return <Paper>
        <Box padding={3} sx={{textAlign: "center"}}>
            <h2 style={{marginTop: 0}}>{
                review ? "Relire une réservation de salle" : "Réserver une salle"
            }</h2>
            <Grid container spacing={2} sx={{marginTop: 2}}>
                <Grid item xs={12} md={6} style={{textAlign: 'left'}}>
                    <StatusIndicatorField status={state.status} label={"Statut: "} review={review}/>
                </Grid>
                <Grid item xs={12} md={6} style={{textAlign: 'right'}}>
                    <InternalNotesField state={state} review={review}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <OrganizationField organizations={organizations} state={state} setState={setState}
                                       review={review} show={isAdmin()}
                                       canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StartDateField state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EndDateField state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12}>
                    <RoomPeriodField state={state} setState={setState} review={review} canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12}>
                    <EventNameField state={state} setState={setState} review={review} canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <RoomSelectField filteredRooms={filteredRooms} state={state} setState={setState}
                                     review={review}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <PriceIndicatorField price={state.price} label={"Prix (indicatif): "}/>
                </Grid>
                <Grid item xs={12}>
                    <ParticipantsField state={state} setState={setState} onChange={filterRooms} label={"Participants"}
                                       review={review}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <GearField roomGears={roomGears} state={state} setState={setState} review={review}
                               canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12}>
                    <DescriptionField state={state} setState={setState} review={review} canEdit={canEdit}/>
                </Grid>
            </Grid>
        </Box>
    </Paper>
}
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

export default function RoomPeriodField({review, state, setState}) {
    return <FormControl>
        <RadioGroup
            row
            spacing={2}
            name="row-radio-buttons-group"
            value={state.period}
            required
            sx={{flexDirection: {xs: "column", md: "row"}}}
        >
            <FormControlLabel
                control={<Radio disabled={review && state.period !== "MORNING"}/>}
                onChange={e => {
                    if (review) return;
                    setState(prev => ({...prev, period: e.target.value}));
                }}
                value="MORNING"
                label="Matin"
            />
            <FormControlLabel
                control={<Radio disabled={review && state.period !== "AFTERNOON"}/>}
                onChange={e => {
                    if (review) return;
                    setState(prev => ({...prev, period: e.target.value}));
                }}
                value="AFTERNOON"
                label="Après-midi"
            />
            <FormControlLabel
                control={<Radio disabled={review && state.period !== "EVENING"}/>}
                onChange={e => {
                    if (review) return;
                    setState(prev => ({...prev, period: e.target.value}));
                }}
                value="EVENING"
                label="Soirée"
            />
            <FormControlLabel
                control={<Radio disabled={review && state.period !== "WHOLE_DAY"}/>}
                onChange={e => {
                    if (review) return;
                    setState(prev => ({...prev, period: e.target.value}));
                }}
                value="WHOLE_DAY"
                label="Journée"
            />
        </RadioGroup>
    </FormControl>
}
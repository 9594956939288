import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-slab/700.css';
import {createTheme, ThemeProvider} from "@mui/material";
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import Login from "./components/Login";
import RequestAccess from "./components/RequestAccess";
import RoomsList from "./components/room/RoomsList";
import Root from "./components/Root";
import {frFR} from "@mui/x-data-grid";
import RoomEditor from "./components/room/RoomEditor";
import "./config/axios";
import LocalStorageService from './service/localStorage'
import {InjectInterceptors} from "./config/axios";
import OrganizationEditor from "./components/organization/OrganizationEditor";
import OrganizationsList from "./components/organization/OrganizationsList";
import UserEditor from "./components/user/UserEditor";
import UsersList from "./components/user/UsersList";
import Main from "./components/Main";
import RequestsList from "./components/accessRequests/RequestsList";
import CaterersList from "./components/caterer/CaterersList";
import CatererEditor from "./components/caterer/CatererEditor";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import 'moment/locale/fr';
import {BookingStartPage} from "./components/booking/BookingStartMenu";
import BookingBoth from "./components/booking/BookingBoth";
import BookingCaterer from "./components/booking/BookingCaterer";
import BookingRoom from "./components/booking/BookingRoom";
import BookingsList from "./components/booking/BookingsList";
import PlanningGenerator from "./components/planning/PlanningGenerator";
import InvoiceGenerator from "./components/invoice/InvoiceGenerator";
import PasswordChanger from "./components/PasswordChanger";
import RoomOccupation from "./components/room/RoomOccupation";
import Home from "./components/home/Home";
import Configuration from "./components/config/Configuration";

moment.locale('fr');

const theme = createTheme({
    palette: {
        primary: {
            main: "#AC013A"
        }
    },
    typography: {
        h1: {fontFamily: "Roboto Slab"},
        h2: {fontFamily: "Roboto Slab"},
        h3: {fontFamily: "Roboto Slab"}
    }
}, frFR);

function Authenticated(props) {
    if (!LocalStorageService.getSessionToken()) {
        return <Navigate to="/login" state={{from: props.location}}/>;
    }
    return <PasswordForcedChangeProvider>{props.children}</PasswordForcedChangeProvider>;
}

function Protected(props) {
    const roles = props.roles ?? [];
    if (!roles.includes(LocalStorageService.getRole()) && roles.length > 0) {
        return <Navigate to="/" state={{from: props.location}}/>
    }
    return props.children ?? <Outlet/>;
}

function Anonymous(props) {
    if (LocalStorageService.getSessionToken()) {
        return <Navigate to="/" state={{from: props.location}}/>;
    }
    return props.children;
}

function PasswordForcedChangeProvider(props) {
    const location = useLocation();

    if (LocalStorageService.getForceChangePassword() && location.pathname !== "/changePassword") {
        return <Navigate to="/changePassword" state={{from: props.location}}/>;
    }
    return props.children;
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
                <InjectInterceptors/>
                <Main>
                    <Routes>
                        <Route path="/" element={<Authenticated><Root/></Authenticated>}>
                            <Route path="rooms" element={<Protected roles={['ADMIN']}/>}>
                                <Route index element={<RoomsList/>}/>
                                <Route path="new" element={<RoomEditor/>}/>
                                <Route path="edit/:id" element={<RoomEditor/>}/>
                            </Route>
                            <Route path="users" element={<Protected roles={['REFERENT', 'ADMIN']}/>}>
                                <Route index element={<UsersList/>}/>
                                <Route path="new" element={<Protected roles={['ADMIN']}/>}>
                                    <Route index element={<UserEditor/>}/>
                                </Route>
                                <Route path=":id/edit" element={<UserEditor/>}/>
                            </Route>
                            <Route path="organizations" element={<Protected roles={["ADMIN"]}/>}>
                                <Route index element={<OrganizationsList/>}/>
                                <Route path="new" element={<OrganizationEditor/>}/>
                                <Route path=":id/edit" element={<OrganizationEditor/>}/>
                            </Route>
                            <Route path="caterers" element={<Protected roles={["ADMIN", "REFERENT"]}/>}>
                                <Route index element={<CaterersList/>}/>
                                <Route path="new" element={<Protected roles={['ADMIN']}/>}>
                                    <Route index element={<CatererEditor/>}/>
                                </Route>
                                <Route path=":id/edit" element={<Protected roles={['ADMIN']}/>}>
                                    <Route index element={<CatererEditor/>}/>
                                </Route>
                                <Route path=":id" element={<CatererEditor readonly/>}/>
                            </Route>
                            <Route path="accessRequests" element={<Protected roles={["ADMIN", "REFERENT"]}/>}>
                                <Route index element={<RequestsList/>}/>
                                <Route path=":id" element={<UserEditor draft/>}/>
                            </Route>
                            <Route path="me" element={<UserEditor me/>}/>
                            <Route path="book">
                                <Route index element={<BookingStartPage/>}/>
                                <Route path="room" element={<BookingRoom/>}/>
                                <Route path="caterer" element={<BookingCaterer/>}/>
                                <Route path="roomAndCaterer" element={<BookingBoth/>}/>
                            </Route>
                            <Route path="bookings">
                                <Route index element={<BookingsList/>}/>
                                <Route path="room/:id" element={<BookingRoom/>}/>
                                <Route path="caterer/:id" element={<BookingCaterer/>}/>
                            </Route>
                            <Route path="bookingRequests">
                                <Route index element={<BookingsList review/>}/>
                                <Route path="room/:id" element={<BookingRoom/>}/>
                                <Route path="caterer/:id" element={<BookingCaterer/>}/>
                            </Route>
                            <Route path="plannings" element={<Protected roles={["ADMIN"]}/>}>
                                <Route index element={<PlanningGenerator/>}/>
                            </Route>
                            <Route path="roomsOccupation">
                                <Route index element={<RoomOccupation/>}/>
                            </Route>
                            <Route path="invoices" element={<Protected roles={["ADMIN"]}/>}>
                                <Route index element={<InvoiceGenerator/>}/>
                            </Route>
                            <Route path="settings" element={<Protected roles={["ADMIN"]}/>}>
                                <Route index element={<Configuration/>}/>
                            </Route>
                            <Route index element={<Home/>}/>
                        </Route>
                        <Route path="/login" element={<Anonymous><Login/></Anonymous>}/>
                        <Route path="/requestAccess" element={<Anonymous><RequestAccess/></Anonymous>}/>
                        <Route path="/changePassword" element={<Authenticated><PasswordChanger/></Authenticated>}/>
                    </Routes>
                </Main>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

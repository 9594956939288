import axios from "axios";
import {Alert, AlertTitle, Box, Button, Collapse, Container, Grid, Paper, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import LocalStorageService from "../service/localStorage";
import {createRef, useState} from "react";

const style = {
    container: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
};

export default function Login() {

    const navigate = useNavigate();

    const email = createRef();
    const password = createRef();
    const [checked, setChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    let lastToastTimeout;

    const showToast = () => {
        clearTimeout(lastToastTimeout);
        setChecked(true);
        lastToastTimeout = setTimeout(() => setChecked(false), 5000);
    }

    function tryLogin() {
        axios.post("/api/auth/login", {
            email: email.current.value,
            password: password.current.value
        }).then(response => {
            LocalStorageService.setTokens(response.data);
            navigate("/");
        }).catch(error => {
            setErrorMessage(error.response.data.message ?? "Une erreur est survenue");
            showToast()
        })
    }

    return (
        <Container maxWidth={"sm"} sx={{p: 3}} style={style.container}>
            <Collapse direction={"up"} in={checked} timeout={400}>
                <Alert severity="error" sx={{marginBottom: 2}}>
                    <AlertTitle>Erreur</AlertTitle>
                    {errorMessage}
                </Alert>
            </Collapse>
            <Paper elevation={3} style={{textAlign: 'center'}}>
                <Box padding={3}>
                    <h1 style={{marginTop: 0}}>Connexion</h1>
                    <p>Afin de bénéficier de toutes les fonctionnalités de ce site, merci de vous identifier.</p>

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        tryLogin();
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth inputRef={email} label="E-mail" type={"email"}
                                           variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth inputRef={password} label="Mot de passe" type={"password"}
                                           variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" type={"submit"}>Valider</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

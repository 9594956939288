import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";
import NavigationDrawer from "./navigation/Drawer";

export default function Root() {
    return (
        <Box sx={{display: "flex", height: "100%", boxSizing: "border-box", overflowY: "clip"}}>
            <NavigationDrawer/>
            <Box component="main" sx={{flexGrow: 1, p: 3, overflowY: "auto"}}>
                <Outlet/>
            </Box>
        </Box>
    )
}
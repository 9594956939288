import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";

export default function SubmitButton({
    action,
    onReset = () => {},
    children,
    ...props
}) {
    const [mounted, setMounted] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => () => setMounted(false), []);

    function accept() {
        if (mounted) {
            setLoading(true);
            action().finally(reset);
        }
    }

    function reset() {
        if (mounted) {
            setLoading(false);
            onReset();
        }
    }

    return (
        <LoadingButton
            {...props}
            loading={loading}
            onClick={accept}>{children}</LoadingButton>
    );
}

import {Box, Button, Paper, Stack, Toolbar} from "@mui/material";
import logo from '../img/logo.png'
import LocalStorageService from "../service/localStorage";
import {useNavigate} from "react-router-dom";

export default function Header() {

    const navigate = useNavigate();

    return (
        <Paper square elevation={5} sx={{zIndex: 5000}}>
            <Toolbar>
                <Box sx={{flexGrow: 1, alignContent: 'start'}}>
                    <img style={{maxHeight: 64}} src={logo} alt="AFUL CGT - Réservation"/>
                </Box>
                <Stack spacing={2} direction="row">{
                    !LocalStorageService.getSessionToken() ? <>
                        <Button variant="outlined" onClick={() => navigate("/requestAccess")}>Demande d'accès</Button>
                        <Button variant="contained" onClick={() => navigate("/login")}>Connexion</Button>
                    </> : <>
                        <p style={{margin: 'auto'}}>Bonjour, <b>{LocalStorageService.getDisplayName()}</b></p>
                        <Button variant="contained" onClick={() => {
                            LocalStorageService.clearSession();
                            navigate("/login");
                        }}>Déconnexion</Button>
                    </>
                }</Stack>
            </Toolbar>
        </Paper>
    );
}

import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";

import {MenuProps} from "../../BookingUtils";

export default function CatererMenuSelectField({catererMenus, state, setState, review}) {
    return <FormControl size={"small"} required fullWidth disabled={state.type !== "CATERER" || review}>
        <InputLabel id={"menuLabel"}>Menu</InputLabel>
        <Select
            labelId={"menuLabel"}
            label="Menu"
            variant="outlined"
            onChange={(e) => {
                setState({...state, menuId: e.target.value});
            }}
            value={review ? 0 : state.menuId}
            inputProps={{readOnly: review}}
            MenuProps={MenuProps}
        >
            {
                review
                    ? <MenuItem value={0} key={"MENU"}>
                        <Stack direction={"row"} spacing={1}>
                            <span>{state.menu.name}</span>
                            <span style={{color: "gray"}}>({state.menu.price / 100} €)</span>
                        </Stack>
                    </MenuItem>
                    : catererMenus.map(({id, name, price}) => {
                        return <MenuItem value={id} key={id}>
                            <Stack direction={"row"} spacing={1}>
                                <span>{name}</span>
                                <span style={{color: "gray"}}>({price / 100} €)</span>
                            </Stack>
                        </MenuItem>
                    })
            }
            {
                !review && catererMenus.length === 0 &&
                <MenuItem key={"NO_MENU"} value={0} disabled>
                    <em>Aucune menu disponible</em>
                </MenuItem>
            }
        </Select>
    </FormControl>
}
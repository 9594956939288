import {FormControl, InputLabel, MenuItem, Select, Stack, Tooltip} from "@mui/material";
import {AutoModeRounded} from "@mui/icons-material";
import {MenuProps} from "../../BookingUtils"

export default function RoomSelectField({state, setState, review, filteredRooms}) {
    return <FormControl size={"small"} fullWidth required disabled={review || state.type === "SELF"}>
        <InputLabel id={"roomLabel"}>Salle</InputLabel>
        <Select
            labelId={"roomLabel"}
            label={"Salle"}
            variant="outlined"
            onChange={e => {
                setState(prev => ({...prev, roomId: e.target.value, gearsId: []}));
            }}
            value={review ? 0 : state.roomId}
            inputProps={{readOnly: review || state.type === "SELF"}}
            MenuProps={MenuProps}
        >
            {
                review
                    ? <MenuItem key={"ROOM"} value={0}>{state.room}</MenuItem>
                    : filteredRooms.map(room => {
                        return <MenuItem value={room.id}
                                         key={room.id}
                                         sx={{display: 'flex', flexDirection: 'row'}}
                        >
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={1}
                            >
                                <span>{room.name}</span>
                                <span
                                    style={{color: "gray"}}>({room.capacity} places, {room.location})</span>
                                {
                                    room.validation === 'AUTOMATIC' &&
                                    <Tooltip title={"Validation automatique"}>
                                        <AutoModeRounded fontSize={"small"} color={"success"}/>
                                    </Tooltip>
                                }
                            </Stack>
                        </MenuItem>
                    })
            }
            {
                !review && filteredRooms.length === 0 &&
                <MenuItem key={"NO_ROOM"} value={0} disabled>
                    <em>Aucune salle disponible</em>
                </MenuItem>
            }
        </Select>
    </FormControl>
}
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useState} from "react";

export default function InternalNotesField({state, review}) {
    let [open, setOpen] = useState(false);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    if (!review || state.status !== "ACCEPTED" || !state.internalNotes) return null;
    return <>
        <Button variant="outlined" onClick={handleOpen}>Voir commentaire</Button>
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>
                {"Commentaire laissé à l'approbation"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {state.internalNotes}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    </>

}
import {
    Alert,
    AlertTitle,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";
import {AddRounded, DeleteRounded} from "@mui/icons-material";
import CustomForm from "../common/CustomForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};

export default function RoomEditor() {

    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [organizations, setOrganizations] = useState([]);
    const [gearName, setGearName] = useState("");
    const [gearPrice, setGearPrice] = useState("");
    const [state, setState] = useState({
        name: "",
        description: "",
        capacity: "0",
        location: "",
        organizationId: 0,
        closed: false,
        publicRoom: true,
        validation: "MANUAL",
        morningPrice: "0.0",
        afternoonPrice: "0.0",
        eveningPrice: "0.0",
        wholeDayPrice: "0.0",
        caterer: false,
        gears: []
    });

    const editing = id !== undefined;

    useEffect(() => {
        (async () => {
            axios.get(`/api/organizations/public`).then(response => {
                setOrganizations(response.data.items.sort((a, b) => a.name > b.name ? 1 : -1));
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des fédérations", {
                    variant: "error",
                    preventDuplicate: true
                });
            })
        })();
    }, [enqueueSnackbar]);

    useEffect(() => {
        if (editing) {
            axios.get("/api/rooms/" + id).then(response => {
                setState({...response.data, gears: response.data.gears.sort((a, b) => a.id > b.id ? 1 : -1)});
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
                navigate("/rooms");
            });
        }
    }, [id, editing, enqueueSnackbar, navigate]);

    async function submit() {
        if (editing) {
            await axios.put(`/api/rooms/${id}`, state).then(response => {
                navigate("/rooms");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        } else {
            await axios.post(`/api/rooms`, state).then(response => {
                navigate("/rooms");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                enqueueSnackbar(error.response.data.message, {
                    variant: error.response.data.severity.toLowerCase(),
                    preventDuplicate: true
                })
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        }
    }

    return (
        <Grid
            container
            alignItems={"stretch"}
            justifyContent={"center"}
            height={"100%"}
        >
            <Grid item xs={12} md={12} lg={10} paddingBottom={3}
                  alignItems={"stretch"}>
                <Paper sx={{p: 3}}>
                    <h2 style={{margin: 0}}>{
                        editing ? "Modifier une salle existante" : "Ajouter une nouvelle salle"
                    }</h2>
                    <CustomForm
                        submitButton={{
                            text: editing ? "Sauvegarder" : "Créer",
                            action: submit,
                        }}
                        backButton={{
                            text: "Retour",
                            action: async () => navigate("/rooms"),
                        }}>
                        <Grid container item spacing={2} lg={12} xl={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Informations générales</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Nom de la salle"
                                    variant="outlined"
                                    onChange={(e) => setState(state => ({
                                        ...state,
                                        name: e.target.value
                                    }))}
                                    value={state.name}
                                    inputProps={{maxLength: 128}}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Emplacement"
                                    variant="outlined"
                                    onChange={(e) => setState(state => ({
                                        ...state,
                                        location: e.target.value
                                    }))}
                                    value={state.location}
                                    inputProps={{maxLength: 64}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Capacité"
                                    type={"number"}
                                    variant="outlined"
                                    onChange={(e) => setState(state => ({
                                        ...state,
                                        capacity: e.target.value
                                    }))}
                                    value={state.capacity}
                                />
                            </Grid>
                            <Grid item xs={4}
                                  sx={{display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={state.caterer}
                                        onChange={(e) => setState(state => ({
                                            ...state,
                                            caterer: e.target.checked
                                        }))}
                                    />}
                                    label="Espace traiteur"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    label="Description"
                                    variant="outlined"
                                    onChange={(e) => setState(state => ({
                                        ...state,
                                        description: e.target.value
                                    }))}
                                    value={state.description}
                                    inputProps={{maxLength: 512}}
                                    helperText={state.description.length + "/512"}
                                />
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'left'}}>
                                <FormControl fullWidth>
                                    <InputLabel id={"organisationSelectLabel"}>Fédération</InputLabel>
                                    <Select
                                        labelId={"organisationSelectLabel"}
                                        label="Fédération"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setState(state => ({
                                                ...state,
                                                publicRoom: e.target.value === 0,
                                                organizationId: e.target.value
                                            }))
                                        }}
                                        value={state.organizationId}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value={0}><em>Aucune</em></MenuItem>
                                        {organizations.map(ref => (<MenuItem value={ref.id} key={ref.id}>
                                            {ref.name}
                                        </MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}
                                  sx={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={state.closed}
                                        onChange={(e) => {
                                            setState(state => ({...state, closed: e.target.checked}))
                                        }}
                                    />}
                                    label="Fermée"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={state.publicRoom}
                                        onChange={(e) => {
                                            setState(state => ({...state, publicRoom: e.target.checked}))
                                        }}
                                    />}
                                    label="Publique"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    <AlertTitle>Méthode de validation</AlertTitle>
                                    <Stack>
                                        {
                                            state.validation === "MANUAL" && <span>
                                                    Cette salle nécessite une validation manuelle de la part d'un
                                                    référent ou d'un administrateur.
                                                </span>
                                        }
                                        {
                                            state.validation === "AUTOMATIC" && <span>
                                                    Cette salle valide automatiquement toutes ses réservations, sans
                                                    intervention d'un référent ni d'un administrateur.
                                                </span>
                                        }
                                        <FormControl sx={{paddingTop: 1}}>
                                            <RadioGroup
                                                row
                                                spacing={2}
                                                value={state.validation}
                                                onChange={e => {
                                                    setState(prev => ({...prev, validation: e.target.value}))
                                                }}>
                                                <FormControlLabel
                                                    sx={{marginLeft: 0}}
                                                    value="MANUAL"
                                                    control={<Radio color={"info"}/>}
                                                    label="Manuelle"/>
                                                <FormControlLabel
                                                    value="AUTOMATIC"
                                                    control={<Radio color={"info"}/>}
                                                    label="Automatique"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Alert>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2} lg={12} xl={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Prix</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'left'}}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Matin"
                                    type={"number"}
                                    variant="outlined"
                                    onChange={e => setState({...state, morningPrice: e.target.value})}
                                    value={state.morningPrice}
                                />
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'left'}}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Après-midi"
                                    type={"number"}
                                    variant="outlined"
                                    onChange={e => setState({...state, afternoonPrice: e.target.value})}
                                    value={state.afternoonPrice}
                                />
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'left'}}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Soirée"
                                    type={"number"}
                                    variant="outlined"
                                    onChange={e => setState({...state, eveningPrice: e.target.value})}
                                    value={state.eveningPrice}
                                />
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'left'}}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Journée complète"
                                    type={"number"}
                                    variant="outlined"
                                    onChange={e => setState({...state, wholeDayPrice: e.target.value})}
                                    value={state.wholeDayPrice}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Équipement disponible</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nom de l'équipement</TableCell>
                                            <TableCell align="right"/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.gears.map(({name, price}, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        size={"small"}
                                                        variant={"standard"}
                                                        type={"number"}
                                                        value={price}
                                                        placeholder={"Prix à la journée..."}
                                                        onChange={e => {
                                                            setState({
                                                                ...state,
                                                                gears: state.gears.map((gear, i) => {
                                                                    if (i === index) {
                                                                        gear.price = e.target.value;
                                                                    }
                                                                    return gear;
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                                        console.log(index)
                                                        setState({
                                                            ...state,
                                                            gears: state.gears.filter((_, i) => i !== index)
                                                        })
                                                    }}>
                                                        <DeleteRounded fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow
                                            key="add"
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Stack spacing={1} direction={"row"}>
                                                    <TextField
                                                        size={"small"}
                                                        variant={"standard"}
                                                        fullWidth
                                                        type={"text"}
                                                        value={gearName}
                                                        placeholder={"Nom d'un nouvel équipement..."}
                                                        onChange={e => {
                                                            setGearName(e.target.value);
                                                        }}
                                                    />
                                                    <TextField
                                                        size={"small"}
                                                        variant={"standard"}
                                                        fullWidth
                                                        type={"number"}
                                                        value={gearPrice}
                                                        placeholder={"Prix à la journée..."}
                                                        onChange={e => {
                                                            setGearPrice(e.target.value);
                                                        }}
                                                    />
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            if (gearName.length <= 0) return;
                                                            if (state.gears.map(gear => gear.name).includes(gearName)) {
                                                                enqueueSnackbar("Cet équipement existe déjà", {variant: "error"});
                                                                return;
                                                            }
                                                            setState({
                                                                ...state,
                                                                gears: [...state.gears, {
                                                                    name: gearName,
                                                                    price: gearPrice
                                                                }]
                                                            })
                                                            setGearName("");
                                                            setGearPrice(0);
                                                        }}
                                                    >
                                                        <AddRounded fontSize="small"/>
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </CustomForm>
                </Paper>
            </Grid>
        </Grid>
    );
}

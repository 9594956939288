import {DataGrid} from "@mui/x-data-grid";
import {Box, Button, Checkbox, Chip, Divider, FormControlLabel, IconButton, Paper, Stack, Tooltip} from "@mui/material";
import {
    AddRounded,
    AutoModeRounded,
    DeleteForeverRounded,
    DoDisturbRounded,
    EditRounded,
    GroupRounded,
    LocalDiningRounded,
    LockOpenRounded
} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {buildParams} from "../../utils/pageable";
import moment from "moment";
import CustomPagination from "../crud/CustomPagination";
import CustomSearch from "../crud/CustomSearch";
import {useQueryClient} from "react-query";
import Queries from "../../service/queries";
import ConfirmDialog from "../common/ConfirmDialog";
import {useEffect, useState} from "react";

function getRoomChips(room) {
    let chips = [];
    if (room.row.organizationId !== 0) {
        chips.push({
            label: "Déléguée",
            color: "warning",
            icon: () => <GroupRounded/>
        })
    }
    if (room.row.publicRoom) {
        chips.push({
            label: "Publique",
            color: "success",
            icon: () => <LockOpenRounded/>
        });
    }
    if (room.row.closed) {
        chips.push({
            label: "Fermée",
            color: "error",
            icon: () => <DoDisturbRounded/>
        })
    }
    if (room.row.caterer) {
        chips.push({
            label: "Espace traiteur",
            color: "info",
            icon: () => <LocalDiningRounded/>
        })
    }
    if (room.row.validation === "AUTOMATIC") {
        chips.push({
            label: "Validation auto.",
            color: "success",
            icon: () => <AutoModeRounded/>
        })
    }
    return chips;
}

export default function RoomsList() {

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [refresh, setRefresh] = useState(true);
    const deleteDialogState = useState(false);
    const [roomId, setRoomId] = useState(null);
    const [freeOnly, setFreeOnly] = useState(false);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 25,
        field: "",
        sort: "asc",
        filter: "",
        rows: [],
        rowCount: 0,
        loading: false,
    });

    async function deleteRoom() {
        if (roomId === null) {
            return;
        }
        await axios.delete(`/api/rooms/${roomId}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
            setRefresh(!refresh);
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
        setRoomId(null);
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Nom',
            description: "Nom de la salle",
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'location',
            headerName: 'Emplacement',
            description: "Emplacement de la salle au sein du complexe",
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'capacity',
            headerName: 'Capacité',
            description: "Capacité d'accueil de la salle",
            minWidth: 80,
            maxWidth: 80,
        },
        {
            field: 'state',
            headerName: 'Propriétés',
            sortable: false,
            flex: 2,
            valueGetter: (params) => getRoomChips(params),
            renderCell: (params) => {
                return (<strong><Stack spacing={1} direction={"row"}>{
                    params.value.map((chip, i) => {
                        return (<Chip variant={"outlined"} color={chip.color} size="small" icon={chip.icon()}
                                      label={chip.label} key={i}/>)
                    })
                }</Stack></strong>)
            }
        },
        {
            field: 'lastModifiedDate',
            headerName: 'Dernière modification',
            minWidth: 250,
            flex: 1,
            valueGetter: (params) => ({
                date: params.row.lastModifiedAt ?? null,
                name: params.row.lastModifiedBy ?? null
            }),
            renderCell: (params) => {
                return <Stack>
                    <span>{
                        params.value.date && (params.value.name
                            ? params.value.name
                            : "Système")
                    }</span>
                    <span>{
                        params.value.date
                            ? <small>{moment(params.value.date).format("Do MMMM YYYY à HH:mm:ss")}</small>
                            : "Jamais"
                    }</span>
                </Stack>;
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            minWidth: 100,
            maxWidth: 100,
            getActions: (params) => [
                <Tooltip title={"Éditer"}>
                    <IconButton size={"small"} color={"warning"} onClick={() => navigate(`edit/${params.id}`)}>
                        <EditRounded fontSize="small"/>
                    </IconButton>
                </Tooltip>,
                <Tooltip title={"Supprimer"}>
                    <IconButton size={"small"} color={"error"} onClick={() => {
                        setRoomId(params.row.id);
                        deleteDialogState[1](true);
                    }}>
                        <DeleteForeverRounded fontSize="small"/>
                    </IconButton>
                </Tooltip>
            ]
        },
    ];

    useEffect(() => {
        let active = true;

        (async () => {
            setRowsState(prev => ({...prev, loading: true}));
            const params = buildParams(rowsState.page, rowsState.pageSize, rowsState.field, rowsState.sort);
            params.append("filter", rowsState.filter);
            if (freeOnly) {
                params.append("freeOnly", freeOnly);
            }
            const newRows = await axios.get(`/api/rooms`, {params}).catch(() => {
                enqueueSnackbar("Échec de la récupération des salles", {
                    variant: "error",
                    preventDuplicate: true
                });
            });

            if (!active) {
                return;
            }

            if (newRows) {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                    rows: newRows.data.items,
                    rowCount: newRows.data.count
                }));
            } else {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                }));
            }
        })();

        return () => {
            active = false;
        };
    }, [rowsState.page, rowsState.pageSize, rowsState.filter, refresh, freeOnly, rowsState.field, rowsState.sort, enqueueSnackbar]);

    return (
        <Paper sx={{height: "100%", width: '100%'}}>
            <DataGrid
                columns={columns}
                pagination
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) => setRowsState(prev => ({...prev, page}))}
                onPageSizeChange={(pageSize) =>
                    setRowsState(prev => ({...prev, pageSize}))
                }
                sortingMode="server"
                onSortModelChange={(sortModel) => {
                    if (sortModel.length > 0) {
                        setRowsState({...rowsState, sort: sortModel[0].sort, field: sortModel[0].field});
                    } else {
                        setRowsState({...rowsState, sort: "asc", field: ""});
                    }
                }}
                disableSelectionOnClick
                components={{
                    Footer: () => {
                        return (<Box>
                            <Divider/>
                            <Stack direction={"row-reverse"} spacing={2} sx={{p: 2, alignItems: "center"}}>
                                <Button variant={"outlined"} color={"success"} startIcon={<AddRounded/>}
                                        onClick={() => navigate("new")}>
                                    Créer une salle
                                </Button>
                                <CustomPagination/>
                                <CustomSearch rowsState={rowsState} setRowsState={setRowsState}/>
                                <FormControlLabel control={<Checkbox checked={freeOnly} onChange={(e) => {
                                    setFreeOnly(e.target.checked)
                                }}/>} label="Publiques seulement"/>
                            </Stack>
                        </Box>)
                    }
                }}
            />
            <ConfirmDialog
                title={"Supprimer une salle"}
                acceptText={"Supprimer"}
                visibleState={deleteDialogState}
                acceptPromise={() => deleteRoom()}>
                Toutes les réservations liées à cette salle seront annulées.
                Êtes-vous sûr(e) de vouloir supprimer cette salle ?
            </ConfirmDialog>
        </Paper>
    );
}

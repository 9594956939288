import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {AddRounded, DeleteRounded} from "@mui/icons-material";
import CustomForm from "../common/CustomForm";

export default function CatererEditor({readonly}) {

    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [menuName, setMenuName] = useState("");
    const [menuPrice, setMenuPrice] = useState(0);
    const [state, setState] = useState({
        id: 0,
        companyName: "",
        firstName: "",
        lastName: "",
        contactMail: "",
        note: "",
        address1: "",
        address2: "",
        zipCode: "",
        city: "",
        country: "France",
        phone: "",
        mobile: "",
        fax: "",
        mail: "",
        closed: false,
        contracted: false,
        intern: false,
        menus: []
    });

    const editing = id !== undefined;

    useEffect(() => {
        if (editing) {
            axios.get(`/api/caterers/${id}`).then(response => {
                setState(() => response.data);
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
                navigate("/organizations");
            });
        }
    }, [id, editing, enqueueSnackbar, navigate]);

    async function submit() {
        if (editing) {
            await axios.put(`/api/caterers/${id}`, state).then(response => {
                navigate("/caterers");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        } else {
            await axios.post(`/api/caterers`, state).then(response => {
                navigate("/caterers");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                enqueueSnackbar(error.response.data.message, {
                    variant: error.response.data.severity.toLowerCase(),
                    preventDuplicate: true
                })
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        }
    }

    return (
        <Grid
            container
            alignItems={"stretch"}
            justifyContent={"center"}
            height={"100%"}
        >
            <Grid item xs={12} md={12} lg={10} paddingBottom={3}
                  alignItems={"stretch"}>
                <Paper sx={{p: 3}}>
                    <h2 style={{margin: 0}}>{
                        readonly ? "Détails du traiteur" :
                            (editing ? "Modifier un traiteur existant" : "Ajouter un nouveau traiteur")
                    }</h2>
                    <CustomForm
                        submitButton={{
                            text: editing ? "Sauvegarder" : "Créer",
                            action: submit,
                        }}
                        backButton={{
                            text: "Retour",
                            action: async () => navigate("/caterers"),
                        }}
                        readonly={readonly}>
                        <Grid container item spacing={2} lg={12} xl={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Informations générales</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Raison sociale"
                                    variant="outlined"
                                    onChange={e => setState({...state, companyName: e.target.value})}
                                    value={state.companyName}
                                    inputProps={{maxLength: 255, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Adresse"
                                    variant="outlined"
                                    onChange={e => setState({...state, address1: e.target.value})}
                                    value={state.address1}
                                    inputProps={{maxLength: 255, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Complément adresse"
                                    variant="outlined"
                                    onChange={e => setState({...state, address2: e.target.value})}
                                    value={state.address2}
                                    inputProps={{maxLength: 255, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Code postal"
                                    variant="outlined"
                                    type="number"
                                    onChange={e => setState({...state, zipCode: e.target.value})}
                                    value={state.zipCode}
                                    inputProps={{maxLength: 32, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Ville"
                                    variant="outlined"
                                    onChange={e => setState({...state, city: e.target.value})}
                                    value={state.city}
                                    inputProps={{maxLength: 128, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Pays"
                                    variant="outlined"
                                    onChange={e => setState({...state, country: e.target.value})}
                                    value={state.country}
                                    inputProps={{maxLength: 128, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={state.closed}
                                        onChange={(e) => {
                                            if (readonly) return;
                                            setState({...state, closed: e.target.checked})
                                        }}
                                    />}
                                    label="Fermé"/>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={state.contracted}
                                        onChange={(e) => {
                                            if (readonly) return;
                                            setState({...state, contracted: e.target.checked})
                                        }}
                                    />}
                                    label="Conventionné"/>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={state.intern}
                                        onChange={(e) => {
                                            if (readonly) return;
                                            setState({...state, intern: e.target.checked})
                                        }}
                                    />}
                                    label="Interne"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline rows={4}
                                    label="Note"
                                    variant="outlined"
                                    onChange={e => setState({...state, note: e.target.value})}
                                    value={state.note}
                                    inputProps={{maxLength: 255, readOnly: readonly}}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2} lg={12} xl={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Informations de contact</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Nom du contact"
                                    variant="outlined"
                                    onChange={e => setState({...state, lastName: e.target.value})}
                                    value={state.lastName}
                                    inputProps={{maxLength: 50, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Prénom du contact"
                                    variant="outlined"
                                    onChange={e => setState({...state, firstName: e.target.value})}
                                    value={state.firstName}
                                    inputProps={{maxLength: 50, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="E-mail de contact"
                                    variant="outlined"
                                    onChange={e => setState({...state, contactMail: e.target.value})}
                                    value={state.contactMail}
                                    inputProps={{maxLength: 100, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    required label="Téléphone"
                                    variant="outlined"
                                    onChange={e => setState({...state, phone: e.target.value})}
                                    value={state.phone}
                                    inputProps={{maxLength: 32, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Mobile"
                                    variant="outlined"
                                    onChange={e => setState({...state, mobile: e.target.value})}
                                    value={state.mobile}
                                    inputProps={{maxLength: 32, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Fax"
                                    variant="outlined"
                                    onChange={e => setState({...state, fax: e.target.value})}
                                    value={state.fax}
                                    inputProps={{maxLength: 32, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="E-mail principal"
                                    variant="outlined"
                                    onChange={e => setState({...state, mail: e.target.value})}
                                    value={state.mail}
                                    inputProps={{maxLength: 32, readOnly: readonly}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Menus proposés</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nom du menu</TableCell>
                                            <TableCell>Prix</TableCell>
                                            <TableCell align="right"/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.menus.map(({name, price}) => (
                                            <TableRow
                                                key={name}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {price / 100} €
                                                </TableCell>
                                                <TableCell align="right">
                                                    {!readonly && <IconButton
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => {
                                                            setState({
                                                                ...state,
                                                                menus: state.menus.filter(m => m.name !== name)
                                                            })
                                                        }}
                                                    >
                                                        <DeleteRounded fontSize="small"/>
                                                    </IconButton>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {!readonly && <TableRow
                                            key="add"
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="td" scope="row">
                                                <TextField
                                                    size={"small"}
                                                    variant={"standard"}
                                                    fullWidth
                                                    type={"text"}
                                                    value={menuName}
                                                    placeholder={"Nom d'un nouveau menu..."}
                                                    onChange={e => setMenuName(e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell component="td" scope="row" colSpan={2}>
                                                <Stack spacing={1} direction={"row"}>
                                                    <TextField
                                                        size={"small"}
                                                        variant={"standard"}
                                                        fullWidth
                                                        type={"number"}
                                                        value={menuPrice}
                                                        onChange={e => setMenuPrice(e.target.value)}
                                                    />
                                                    <IconButton aria-label="add" size="small" onClick={() => {
                                                        if (menuName.length <= 0) return;
                                                        if (state.menus.map(menu => menu.name).includes(menuName)) {
                                                            enqueueSnackbar("Ce menu existe déjà", {variant: "error"});
                                                            return;
                                                        }
                                                        if (menuPrice < 0) {
                                                            enqueueSnackbar("Le prix doit être positif", {variant: "error"});
                                                            return;
                                                        }
                                                        setState({
                                                            ...state, menus: [...state.menus, {
                                                                name: menuName,
                                                                price: menuPrice * 100
                                                            }]
                                                        })
                                                        setMenuName("");
                                                        setMenuPrice(0);
                                                    }}>
                                                        <AddRounded fontSize="small"/>
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </CustomForm>
                </Paper>
            </Grid>
        </Grid>
    );
}

import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

export default function CatererPeriodField({state, setState, review}) {
    return <FormControl>
        <RadioGroup
            row
            spacing={2}
            value={state.period}
            onChange={e => {
                if (review) return;
                setState(prev => ({...prev, period: e.target.value}))
            }}
            required
            sx={{flexDirection: {xs: "column", md: "row"}}}
        >
            <FormControlLabel
                value="BREAKFAST"
                control={<Radio
                    disabled={state.type === "SELF" || (review && state.period !== "BREAKFAST")}/>}
                label="Petit-déjeuner"
            />
            <FormControlLabel
                value="LUNCH"
                control={<Radio disabled={review && state.period !== "LUNCH"}/>}
                label="Déjeuner"
            />
            <FormControlLabel
                value="DINNER"
                control={<Radio
                    disabled={state.type === "SELF" || (review && state.period !== "DINNER")}/>}
                label="Dîner"
            />
        </RadioGroup>
    </FormControl>
}
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";

/**
 * Represents a dialog that asks the user to confirm an action.
 *
 * @param title The title of the dialog.
 * @param visibleState A state variable that determines whether the dialog is visible.
 * @param acceptText The text to display on the accept button.
 * @param rejectText The text to display on the reject button.
 * @param acceptPromise A promise that is resolved when the user accepts the dialog.
 * @param children The content of the dialog.
 */
export default function ConfirmDialog({
    title,
    visibleState,
    acceptText = "Procéder",
    rejectText = "Annuler",
    acceptPromise,
    children,
}) {
    const [mounted, setMounted] = useState(true);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = visibleState;

    useEffect(() => () => setMounted(false), []);

    function accept() {
        if (mounted) {
            setLoading(true);
            acceptPromise().then(reset).catch(reset);
        }
    }

    function reject() {
        reset()
    }

    function reset() {
        if (mounted) {
            setLoading(false);
            setVisible(false);
        }
    }

    return (
        <Dialog open={visible} onClose={() => setVisible(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{children}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={reject} disabled={loading}>{rejectText}</Button>
                <LoadingButton onClick={accept} loading={loading}>{acceptText}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

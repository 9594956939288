import {Box, Button, Grid, Paper} from "@mui/material";
import {ArrowForwardRounded} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import meetingIllustration from "../../img/meeting.png";
import lunchIllustration from "../../img/lunch.png";
import bothIllustration from "../../img/both.png";

export default function BookingStartMenu() {
    const navigate = useNavigate();

    return (
        <Grid container spacing={4} display={"flex"}>
            <Grid item md={4} sm={12} sx={{display: "flex", flexGrow: 1}}>
                <Paper sx={{display: "flex", flexDirection: "column"}}>
                    <img src={meetingIllustration} alt="Illustration d'une réunion" style={{
                        maxWidth: "100%",
                        borderRadius: "4px 4px 0 0"
                    }}/>
                    <Box padding={2} sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                        <h2 style={{margin: 0}}>Réunion uniquement</h2>
                        <p>Réserver simplement une salle de réunion, sans option de repas.</p>
                        <Box textAlign={"right"}
                             sx={{display: "flex", flexGrow: 1, alignItems: "end", justifyContent: "end"}}>
                            <Button variant={"contained"} endIcon={<ArrowForwardRounded/>} onClick={() => {
                                navigate("/book/room");
                            }} sx={{display: "absolute", bottom: 0, right: 0}}>Réserver</Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item md={4} sm={12} sx={{display: "flex", flexGrow: 1}}>
                <Paper sx={{display: "flex", flexDirection: "column"}}>
                    <img src={lunchIllustration} alt="Illustration d'un espace convivial" style={{
                        maxWidth: "100%",
                        borderRadius: "4px 4px 0 0"
                    }}/>
                    <Box padding={2} sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                        <h2 style={{margin: 0}}>Traiteur uniquement</h2>
                        <p>Réserver un espace traiteur pour un repas, sans salle de réunion.</p>
                        <Box textAlign={"right"}
                             sx={{display: "flex", flexGrow: 1, alignItems: "end", justifyContent: "end"}}>
                            <Button variant={"contained"} endIcon={<ArrowForwardRounded/>} onClick={() => {
                                navigate("/book/caterer");
                            }} sx={{display: "absolute", bottom: 0, right: 0}}>Réserver</Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item md={4} sm={12} sx={{display: "flex", flexGrow: 1}}>
                <Paper sx={{display: "flex", flexDirection: "column"}}>
                    <img src={bothIllustration} alt="Illustration de réunions avec traiteur" style={{
                        maxWidth: "100%",
                        borderRadius: "4px 4px 0 0"
                    }}/>
                    <Box padding={2} sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                        <h2 style={{margin: 0}}>Réunion et repas</h2>
                        <p>Réserver une salle de réunion et choisir une option de repas entre le self et un
                            traiteur.</p>
                        <Box textAlign={"right"}
                             sx={{display: "flex", flexGrow: 1, alignItems: "end", justifyContent: "end"}}>
                            <Button variant={"contained"} endIcon={<ArrowForwardRounded/>} onClick={() => {
                                navigate("/book/roomAndCaterer");
                            }} sx={{display: "absolute", bottom: 0, right: 0}}>Réserver</Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export function BookingStartPage() {
    return (
        <Box paddingX={{xl: 16, lg: 12}} sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <BookingStartMenu/>
        </Box>
    )
}

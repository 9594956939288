export function buildParams(page, size, field, sort) {
    let params = new URLSearchParams();

    params.append('page', page);
    params.append('size', size);
    if (field && sort) {
        params.append('sort', `${field},${sort}`);
    }
    return params;
}

export function buildParamsMultipleSorts(page, size, sorts, filters) {
    let params = new URLSearchParams();

    params.append('page', page);
    params.append('size', size);
    for (const {field, direction} of sorts) {
        params.append('sort', `${field},${direction}`);
    }
    for (const {field, value} of filters) {
        params.append(field, value);
    }
    return params;
}
import {
    Alert,
    AlertTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    Switch,
    TableContainer,
    TableFooter,
    TextField,
    Tooltip
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CustomForm from "../common/CustomForm";
import axios from "axios";
import {useSnackbar} from "notistack";
import {AddCircleOutlineRounded, AutoModeRounded, DeleteRounded, ScheduleSendRounded} from "@mui/icons-material";
import {useEffect, useState} from "react";

export default function Configuration() {

    const {enqueueSnackbar} = useSnackbar();

    const [newEmail, setNewEmail] = useState("");
    const [newEmailError, setNewEmailError] = useState(null);
    const [state, setState] = useState({
        sendWeeklyPlanning: false,
        validateUpcomingBookings: false,
        bookingCancellationEmailsList: []
    })

    useEffect(() => {
        axios.get("/api/config").then(response => {
            setState(oldState => ({
                ...oldState,
                ...response.data
            }));
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }, [enqueueSnackbar]);

    function addEmail() {
        if (newEmail === "") {
            return;
        }
        if (state.bookingCancellationEmailsList.includes(newEmail)) {
            setNewEmailError("L'adresse e-mail est déjà dans la liste")
            return;
        }

        setState(oldState => ({
            ...oldState,
            bookingCancellationEmailsList: [...oldState.bookingCancellationEmailsList, newEmail]
        }));
        setNewEmail("");
    }

    function deleteEmail(index) {
        setState(oldState => ({
            ...oldState,
            bookingCancellationEmailsList: oldState.bookingCancellationEmailsList.filter((email, i) => i !== index)
        }));
    }

    function renderEmailLine(email, index) {
        return (
            <TableRow key={index}>
                <TableCell>
                    {email}
                </TableCell>
                <TableCell align={"right"}>
                    <Tooltip title={"Supprimer"}>
                        <IconButton size={"small"} onClick={() => deleteEmail(index)}>
                            <DeleteRounded fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }

    async function submit() {
        await axios.put("/api/config", state).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    return (
        <Grid
            container
            alignItems={"stretch"}
            justifyContent={"center"}
            height={"100%"}
        >
            <Grid item lg={12} xl={10} paddingBottom={3}
                  alignItems={"stretch"}>
                <Paper sx={{p: 3}}>
                    <h2 style={{margin: 0}}>Configuration globale</h2>
                    <CustomForm
                        submitButton={{
                            text: "Sauvegarder",
                            action: submit,
                            icon: null
                        }}
                        showBackButton={false}>
                        <Grid container item spacing={2} md={12} lg={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Tâches programmées</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert color={"primary"} icon={<ScheduleSendRounded/>}>
                                    <AlertTitle>Envoi automatique du planning hebdomadaire</AlertTitle>
                                    <Stack>
                                        <span>
                                            Tous les vendredis à 15h00, un planning de la semaine à venir sera envoyé.
                                        </span>
                                        <FormControlLabel
                                            sx={{paddingTop: 1, marginLeft: 0}}
                                            control={<Switch
                                                checked={state.sendWeeklyPlanning}
                                                onChange={(e) => setState(state => ({
                                                    ...state,
                                                    sendWeeklyPlanning: e.target.checked
                                                }))}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                            label={state.sendWeeklyPlanning ? "Activé" : "Désactivé"}
                                        />
                                    </Stack>
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert color={"primary"} icon={<AutoModeRounded/>}>
                                    <AlertTitle>Validation automatique des réservations à venir</AlertTitle>
                                    <Stack>
                                        <span>
                                            Tous les jours à 00h00, les réservations du lendemain seront automatiquement validées si elles sont toujours en attente.
                                        </span>
                                        <FormControlLabel
                                            sx={{paddingTop: 1, marginLeft: 0}}
                                            control={<Switch
                                                checked={state.validateUpcomingBookings}
                                                onChange={(e) => setState(state => ({
                                                    ...state,
                                                    validateUpcomingBookings: e.target.checked
                                                }))}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                            label={state.validateUpcomingBookings ? "Activé" : "Désactivé"}
                                        />
                                    </Stack>
                                </Alert>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2} md={12} lg={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Réservations</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{margin: "0 0 5.6px 0"}}>
                                    <b>Notifications d'annulation</b>
                                </p>
                                <span style={{fontSize: 14}}>
                                    Liste des adresses e-mail auxquelles envoyer les notifications d'annulation de
                                    réservations :
                                </span>
                                <Paper elevation={0} variant={"outlined"}
                                       sx={{marginTop: 2, width: '100%', overflow: 'hidden'}}>
                                    <TableContainer sx={{maxHeight: "250px"}}>
                                        <Table size="small" stickyHeader>
                                            <TableBody>
                                                {
                                                    state.bookingCancellationEmailsList.map((email, index) => (
                                                        renderEmailLine(email, index)
                                                    ))
                                                }
                                                {
                                                    state.bookingCancellationEmailsList.length === 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={2} align={"center"}>
                                                            <p style={{fontSize: 14, color: "gray"}}>
                                                                Aucune adresse e-mail
                                                            </p>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                            <TableFooter sx={{
                                                backgroundColor: "white",
                                                position: "sticky",
                                                bottom: 0
                                            }}>
                                                <TableRow>
                                                    <TableCell sx={{
                                                        borderBottom: "none",
                                                        borderTop: "1px solid rgba(224, 224, 224, 1)"
                                                    }} colSpan={2}>
                                                        <Stack direction={"row"} spacing={1}>
                                                            <TextField
                                                                fullWidth
                                                                variant={"standard"}
                                                                size={"small"}
                                                                placeholder={"Ajouter une adresse e-mail"}
                                                                type={"email"}
                                                                value={newEmail}
                                                                error={newEmailError !== null}
                                                                helperText={newEmailError}
                                                                onChange={(e) => {
                                                                    setNewEmail(e.target.value);
                                                                    setNewEmailError(null);
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Tab") {
                                                                        e.preventDefault();
                                                                        addEmail();
                                                                    }
                                                                }}
                                                            />
                                                            <Tooltip title={"Ajouter (Tab)"}>
                                                                <IconButton size={"small"} onClick={addEmail}>
                                                                    <AddCircleOutlineRounded fontSize={"small"}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </CustomForm>
                </Paper>
            </Grid>
        </Grid>
    )
}

import {MenuItem, TextField} from "@mui/material";

export default function CatererTypeField({state, setState, review}) {
    return <TextField
        fullWidth
        select
        label="Type"
        variant="outlined"
        size={"small"}
        onChange={e => {
            setState(prev => ({...prev, type: e.target.value}))
            if (e.target.value === "SELF") {
                setState(prev => ({
                    ...prev,
                    period: "LUNCH",
                    catererId: "",
                    roomId: "",
                    menuId: "",
                    name: "Réservation de repas",
                    notes: "",
                }))
            }
        }}
        value={state.type}
        required
        inputProps={{readOnly: review}}
        disabled={review}
    >
        <MenuItem value={"SELF"}>Self</MenuItem>
        <MenuItem value={"CATERER"}>Traiteur</MenuItem>
    </TextField>
}
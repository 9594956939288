import LocalStorageService from "../../service/localStorage";

export function getCurrentDay(dayOffset = 0) {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    return new Date(currentDate.getTime() + dayOffset * 24 * 60 * 60 * 1000);
}

export function isAdmin() {
    return LocalStorageService.getRole().toUpperCase() === 'ADMIN';
}

export function computeRoomPrice(room, state) {
    if (!room) {
        return 0;
    }
    const days = Math.round((state.endTime - state.startTime) / (1000 * 60 * 60 * 24)) + 1;
    if (days < 0) {
        return 0
    }
    switch (state.period) {
        case "WHOLE_DAY":
            return room.wholeDayPrice * days;
        case "MORNING":
            return room.morningPrice * days;
        case "AFTERNOON":
            return room.afternoonPrice * days;
        case "EVENING":
            return room.eveningPrice * days;
        default:
            return 0;
    }
}

export function canEditBooking(authorId, organizationId, endTime) {
    const role = LocalStorageService.getRole().toUpperCase();
    const userOrganizationId = LocalStorageService.getUserOrganization();
    const userId = LocalStorageService.getUserId();
    switch (role) {
        case 'ADMIN':
            return true;
        case 'REFERENT':
            return userOrganizationId === organizationId && Date.now() <= Date.parse(endTime);
        default:
            return userId === authorId && Date.now() <= Date.parse(endTime);
    }
}

export function canApproveBooking(status, organizationId) {
    if (status !== "PENDING") return false;
    const role = LocalStorageService.getRole().toUpperCase();
    const userOrganizationId = LocalStorageService.getUserOrganization();
    switch (role) {
        case "ADMIN":
            return true;
        case "REFERENT":
            return userOrganizationId === organizationId;
        default:
            return false;
    }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};
import {Chip, Typography} from "@mui/material";
import {WarningRounded} from "@mui/icons-material";

function renderSwitch(status) {
    switch (status) {
        case "PENDING":
            return <Chip label="En attente" color="warning" variant="outlined" size="small"/>;
        case "ACCEPTED":
            return <Chip label="Acceptée" color="success" variant="outlined" size="small"/>;
        case "REJECTED":
            return <Chip label="Annulée" color="error" variant="outlined" size="small"/>;
        case "CANCELLED":
            return <Chip label="Annulée" color="error" variant="outlined" size="small"/>;
        default:
            return <Chip label="Inconnu" variant="filled" color="error" size="small"
                         icon={<WarningRounded fontSize={"small"}/>}/>;
    }
}

export default function StatusIndicatorField({status, label, review}) {
    if (!review) return null;
    return <Typography variant="body1">
        {
            label
        }
        {
            renderSwitch(status)
        }
    </Typography>
}
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Checkbox, Divider, FormControlLabel, Grid, Paper, TextField} from "@mui/material";
import CustomForm from "../common/CustomForm";

export default function OrganizationEditor() {
    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [state, setState] = useState({
        id: 0,
        name: "",
        address1: "",
        address2: "",
        zipCode: "",
        city: "",
        country: "France",
        phone: "",
        fax: "",
        mail: "",
        closed: false,
        sageBillingID: ""
    });

    const editing = id !== undefined;

    useEffect(() => {
        if (editing) {
            axios.get(`/api/organizations/${id}`).then(response => {
                setState(() => response.data);
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
                navigate("/organizations");
            });
        }
    }, [id, editing, enqueueSnackbar, navigate]);

    async function submit() {
        if (editing) {
            await axios.put(`/api/organizations/${id}`, state).then(response => {
                navigate("/organizations");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        } else {
            await axios.post(`/api/organizations`, state).then(response => {
                navigate("/organizations");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                enqueueSnackbar(error.response.data.message, {
                    variant: error.response.data.severity.toLowerCase(),
                    preventDuplicate: true
                })
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        }
    }

    return (
        <Grid
            container
            alignItems={"stretch"}
            justifyContent={"center"}
            height={"100%"}
        >
            <Grid item xs={12} md={12} lg={9} xl={6} paddingBottom={3}
                  alignItems={"stretch"}>
                <Paper sx={{p: 3}}>
                    <h2 style={{margin: 0}}>{
                        editing ? "Modifier une fédération existante" : "Ajouter une nouvelle fédération"
                    }</h2>
                    <CustomForm
                        submitButton={{
                            text: editing ? "Sauvegarder" : "Créer",
                            action: submit,
                        }}
                        backButton={{
                            text: "Retour",
                            action: async () => navigate("/organizations"),
                        }}>
                        <Grid container item spacing={2} alignItems={"center"} alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <h3 style={{marginBottom: ".5rem"}}>Informations générales</h3>
                                <Divider/>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField required fullWidth label="Raison sociale" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, name: e.target.value}))
                                } value={state.name} inputProps={{maxLength: 255}}/>
                            </Grid>
                            <Grid item xs={3} sx={{display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
                                <FormControlLabel control={<Checkbox checked={state.closed} onChange={e =>
                                    setState(prev => ({...prev, closed: e.target.checked}))
                                }/>} label="Fermée"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required fullWidth label="Adresse" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, address1: e.target.value}))
                                } value={state.address1} inputProps={{maxLength: 255}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Complément adresse" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, address2: e.target.value}))
                                } value={state.address2} inputProps={{maxLength: 255}}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth required label="Code postal" variant="outlined" type="number" onChange={e =>
                                    setState(prev => ({...prev, zipCode: e.target.value}))
                                } value={state.zipCode} inputProps={{maxLength: 32}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth required label="Ville" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, city: e.target.value}))
                                } value={state.city} inputProps={{maxLength: 128}}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth required label="Pays" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, country: e.target.value}))
                                } value={state.country} inputProps={{maxLength: 128}}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth required label="Téléphone" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, phone: e.target.value}))
                                } value={state.phone} inputProps={{maxLength: 32}}/>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Fax" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, fax: e.target.value}))
                                } value={state.fax} inputProps={{maxLength: 32}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth required label="E-mail" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, mail: e.target.value}))
                                } value={state.mail} inputProps={{maxLength: 32}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Cade facturation SAGE" variant="outlined" onChange={e =>
                                    setState(prev => ({...prev, sageBillingID: e.target.value}))
                                } value={state.sageBillingID} inputProps={{maxLength: 32}}/>
                            </Grid>
                        </Grid>
                    </CustomForm>
                </Paper>
            </Grid>
        </Grid>
    );

}
import {TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {isAdmin} from "../../BookingUtils";

export default function EndDateField({review, state, setState}) {
    return <DesktopDatePicker
        label="Fin"
        inputFormat="DD/MM/yyyy"
        value={state.endTime}
        disablePast={!review && !isAdmin()}
        onChange={(e, input) => {
            if (e != null && e.isValid() && (!input || input.length === 10)) {
                setState(prev => ({...prev, endTime: new Date(e.format("YYYY-MM-DD"))}));
            }
        }}
        renderInput={(params) => <TextField size={"small"} fullWidth {...params} required/>}
        readOnly={review}
        disabled={review}
    />
}
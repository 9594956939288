import {Box, Button, Divider, Grid, Paper, Stack, TextField} from "@mui/material";
import axios from "axios";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import {useSnackbar} from "notistack";
import SubmitButton from "../common/SubmitButton";
import {useState} from "react";

export default function InvoiceGenerator() {

    const {enqueueSnackbar} = useSnackbar();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dates, setDates] = useState({
        start: moment(),
        end: moment().add(1, "month")
    });

    async function generateInvoice() {
        if (dates.start.isAfter(dates.end)) {
            setErrorMessage("La date de début doit être antérieure à la date de fin");
            setError(true);
            return
        }
        await axios('/api/invoices/generate', {
            responseType: 'blob',
            params: {
                from: dates.start.toISOString(),
                to: dates.end.toISOString()
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: response.headers["content-type"]
            }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `facture_${dates.start.format('DD-MM-YYYY')}_${dates.end.format('DD-MM-YYYY')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            enqueueSnackbar(error.response.data.message, {variant: "error"});
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    async function generateInvoiceDetail() {
        if (dates.start.isAfter(dates.end)) {
            setErrorMessage("La date de début doit être antérieure à la date de fin");
            setError(true);
            return
        }
        await axios('/api/invoices/detail/generate', {
            responseType: 'blob',
            params: {
                from: dates.start.toISOString(),
                to: dates.end.toISOString()
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: response.headers["content-type"]
            }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `facture_details_${dates.start.format('DD-MM-YYYY')}_${dates.end.format('DD-MM-YYYY')}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            enqueueSnackbar(error.response.data.message, {variant: "error"});
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    function selectLastMonth() {
        const start = moment().subtract(1, "month").startOf("month");
        const end = moment().subtract(1, "month").endOf("month");
        setDates({
            start: start,
            end: end
        })
    }

    return (
        <Box paddingX={{xs: 0, sm: 0, md: 12, lg: 18, xl: 36}} paddingY={{xs: 14, sm: 14, md: 16, lg: 18, xl: 20}}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper sx={{p: 3}}>
                        <h2 style={{marginTop: 0}}>Générer une facture</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DesktopDatePicker
                                    label="Début"
                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(params) => <TextField
                                        fullWidth {...params}
                                        required
                                        error={error}
                                        helperText={errorMessage}
                                    />}
                                    value={dates.start.toDate()}
                                    onChange={(date) => {
                                        if (date === null) return;
                                        setError(false)
                                        setErrorMessage(null)
                                        if (!errorMessage) {
                                            // If there is no error message, allow start date to sync with end date
                                            const difference = date - dates.start.toDate();
                                            dates.end.add(difference, "ms")
                                        }
                                        setDates({
                                            ...dates,
                                            start: date,
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DesktopDatePicker
                                    label="Fin"
                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(params) => <TextField
                                        fullWidth {...params}
                                        required
                                        error={error}
                                    />}
                                    value={dates.end.toDate()}
                                    onChange={(date) => {
                                        if (date === null) return;
                                        setError(false)
                                        setErrorMessage(null)
                                        setDates({...dates, end: date})
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Stack spacing={2} divider={<Divider/>}>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{marginTop: 2}} spacing={2}>
                                <Stack direction={"row"} spacing={2}>
                                    <Button variant={"text"} onClick={selectLastMonth}>Mois dernier</Button>
                                </Stack>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{marginTop: 2}} spacing={2}>
                                <SubmitButton sx={{width: 200}} variant={"contained"} action={generateInvoiceDetail}>Générer
                                    ZIP</SubmitButton>
                                <p>Génère un fichier ZIP avec pour chaque fédération un PDF avec les
                                    détails de leur facturation</p>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{marginTop: 2}} spacing={2}>
                                <SubmitButton sx={{width: 180}} variant={"contained"} action={generateInvoice}>Générer
                                    CSV</SubmitButton>
                                <p>Génère un fichier CSV contenant les informations de facturation pour SAGE</p>
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

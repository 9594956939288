import {Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField} from "@mui/material";
import axios from "axios";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import moment from "moment";
import {useSnackbar} from "notistack";
import SubmitButton from "../common/SubmitButton";
import {useEffect, useState} from "react";

export default function PlanningGenerator() {

    const {enqueueSnackbar} = useSnackbar();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dates, setDates] = useState({
        start: moment(),
        end: moment().add(2, "week")
    });
    const [rooms, setRooms] = useState([]);
    const [roomId, setRoomId] = useState(0);

    useEffect(() => {
        axios.get('/api/rooms/all').then(response => {
            if (!response.data.items.map(room => room.id).includes(roomId)) {
                setRoomId(0)
            }
            setRooms(response.data.items.sort((a, b) => a.name > b.name ? 1 : -1));
        }).catch(() => {
            enqueueSnackbar("Échec de la récupération des salles", {
                variant: "error",
                preventDuplicate: true
            });
        });
    }, [enqueueSnackbar, setRoomId, setRooms, roomId]);

    async function generate() {
        if (dates.start.isAfter(dates.end)) {
            setErrorMessage("La date de début doit être antérieure à la date de fin");
            setError(true);
            return
        }
        await axios('/api/plannings/generate', {
            responseType: 'blob',
            params: {
                from: dates.start.toISOString(),
                to: dates.end.toISOString(),
                roomId: roomId
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: response.headers["content-type"]
            }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `planning_${dates.start.format('DD-MM-YYYY')}_${dates.end.format('DD-MM-YYYY')}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            enqueueSnackbar(error.response.data.message, {variant: "error"});
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    return (
        <Box paddingX={{xs: 0, sm: 0, md: 12, lg: 20, xl: 48}} paddingY={{xs: 14, sm: 14, md: 16, lg: 18, xl: 20}}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper sx={{p: 3}}>
                        <h2 style={{marginTop: 0}}>Générer un planning</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DesktopDatePicker
                                    label="Début"
                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(params) => <TextField
                                        fullWidth {...params}
                                        required
                                        error={error}
                                        helperText={errorMessage}
                                    />}
                                    value={dates.start.toDate()}
                                    onChange={(date) => {
                                        if (date === null) return;
                                        setError(false)
                                        setErrorMessage(null)
                                        setDates({...dates, start: date})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DesktopDatePicker
                                    label="Fin"
                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(params) => <TextField
                                        fullWidth {...params}
                                        required
                                        error={error}
                                    />}
                                    value={dates.end.toDate()}
                                    onChange={(date) => {
                                        if (date === null) return;
                                        setError(false)
                                        setErrorMessage(null)
                                        setDates({...dates, end: date})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl size={"small"} fullWidth required>
                                    <InputLabel id={"roomLabel"}>Salle</InputLabel>
                                    <Select
                                        labelId={"roomLabel"}
                                        label={"Salle"}
                                        variant="outlined"
                                        onChange={e => {
                                            setRoomId(e.target.value)
                                        }}
                                        value={roomId}
                                    >
                                        <MenuItem value={0} key={0} sx={{display: 'flex', flexDirection: 'row'}}>
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                spacing={1}
                                            >
                                                <span>Toutes</span>
                                            </Stack>
                                        </MenuItem>
                                        {
                                            rooms.map(room => {
                                                return <MenuItem value={room.id}
                                                                 key={room.id}
                                                                 sx={{display: 'flex', flexDirection: 'row'}}
                                                >
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={1}
                                                    >
                                                        <span>{room.name}</span>
                                                    </Stack>
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Stack direction={"row-reverse"} sx={{marginTop: 2}}>
                            <SubmitButton variant={"contained"} action={generate}>Générer</SubmitButton>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

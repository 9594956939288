import {Box, Grid,} from "@mui/material";
import {useEffect, useState} from "react";
import BookCatererForm from "./form/BookCatererForm";
import axios from "axios";
import {useSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import Queries from "../../service/queries";
import {useQueryClient} from "react-query";
import {canApproveBooking, canEditBooking, getCurrentDay} from "./BookingUtils";
import BookingActions from "./form/BookingActions";
import LocalStorage from "../../service/localStorage";

export default function BookingCaterer() {

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {id} = useParams();

    const [catererState, setCatererState] = useState({
        startTime: getCurrentDay(3),
        endTime: getCurrentDay(3),
        type: "SELF",
        period: "LUNCH",
        guests: "",
        caterer: "",
        catererId: "",
        room: "",
        roomId: "",
        menu: {
            name: "",
            price: 0
        },
        menuId: "",
        name: "Réservation de repas",
        notes: "",
        price: 0,
        authorId: 0,
        organizationId: LocalStorage.getUserOrganization(),
        internalNotes: "",
        status: "PENDING"
    });

    const [canEdit, setCanEdit] = useState(false);
    const [canApprove, setCanApprove] = useState(false);
    const review = id !== undefined;

    useEffect(() => {
        if (review) {
            axios.get(`/api/booking/${id}`).then(response => {
                setCatererState(response.data);
                setCanEdit(canEditBooking(response.data.authorId, response.data.organizationId, response.data.endTime));
                setCanApprove(canApproveBooking(response.data.status, response.data.organizationId));
            });
        }
    }, [id, review, enqueueSnackbar]);

    async function approveBooking(comments) {
        await axios.post(`/api/booking/approve/${id}`, {
            internalNotes: comments
        }).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: false
            })
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
            queryClient.invalidateQueries(Queries.ROOM_BOOKINGS);
            navigate(-1);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenu", {variant: "error"});
        });
    }

    async function declineBooking() {
        await axios.post(`/api/booking/reject/${id}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: false
            })
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
            queryClient.invalidateQueries(Queries.ROOM_BOOKINGS);
            navigate(-1);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        });
    }

    async function submit() {
        if (review) return; /* Just in case */
        await axios.post("/api/booking/caterer", catererState).then(response => {
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
            navigate("/bookings");
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    async function modifyBooking() {
        await axios.patch("/api/booking/" + id, catererState).then(response => {
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    function onSubmit(e) {
        e.preventDefault();
    }

    return <Box>
        <form onSubmit={onSubmit}>
            <Grid container spacing={{xs: 4}} alignItems={"center"} justifyContent={"center"}>
                <Grid item md={12} lg={8.5} xl={6}>
                    <BookCatererForm formState={[catererState, setCatererState]} review={review} canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12}>
                    <BookingActions review={review} canApprove={canApprove} canEdit={canEdit}
                                    onModify={modifyBooking} onApprove={approveBooking} onDecline={declineBooking}
                                    onSubmit={submit}/>
                </Grid>
            </Grid>
        </form>
    </Box>

}
import {Typography} from "@mui/material";

export default function PriceIndicatorField({price, label}) {
    return <Typography variant="body1">
        {
            label
        }
        {
            new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(
                price)
        }
    </Typography>
}
import {TextField} from "@mui/material";

export default function DescriptionField({state, setState, review, canEdit}) {
    return <TextField
        fullWidth
        multiline
        rows={4}
        label="Description"
        variant="outlined"
        size={"small"}
        onChange={(e) => setState(state => ({
            ...state,
            notes: e.target.value
        }))}
        value={state.notes}
        inputProps={{maxLength: 512, readOnly: review && !canEdit}}
        disabled={review && !canEdit}
        helperText={state.notes.length + "/512"}
    />
}
import ConfirmDialog from "../../common/ConfirmDialog";
import ApprovePopover from "../ApprovePopover";
import {Box, Button, Stack} from "@mui/material";
import {ArrowBackRounded, CheckRounded, CloseRounded, Edit} from "@mui/icons-material";
import SubmitButton from "../../common/SubmitButton";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function BookingActions({review, canApprove, canEdit, onModify, onApprove, onDecline, onSubmit}) {
    const approveDialogState = useState(false);
    const declineDialogState = useState(false);
    const [approving, setApproving] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const actions = [];
    if (review) {
        if (canApprove) {
            actions.push(
                <Button variant="contained" size="large" startIcon={<CloseRounded/>}
                        onClick={() => {
                            declineDialogState[1](true);
                        }}>Annuler</Button>,
                <Button variant="contained" size="large" startIcon={<CheckRounded/>}
                        disabled={approving}
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setApproving(true);
                        }}>Approuver</Button>
            );
        }
        if (canEdit) {
            actions.push(
                <Button variant="contained" size="large" startIcon={<Edit/>}
                        onClick={() => {
                            onModify();
                        }}>Modifier</Button>);
        }
    } else {
        actions.push(<Box sx={{display: "flex", justifyContent: "center"}}>
            <SubmitButton
                variant="contained"
                size="large"
                action={onSubmit}
                type={"submit"}>Valider la demande</SubmitButton>
        </Box>);
    }
    actions.push(<Button variant="contained" size="large" startIcon={<ArrowBackRounded/>}
                         onClick={() => {
                             navigate(-1);
                         }}>Retour</Button>)

    return <>
        <Stack sx={{display: "flex", justifyContent: "center", flexDirection: "row"}} spacing={2}
               direction={"row"}>
            {actions}
        </Stack>
        <ConfirmDialog
            title={"Approuver une demande de réservation"}
            acceptText={"Approuver"}
            visibleState={approveDialogState}
            acceptPromise={() => onApprove()}>
            Êtes-vous sûr(e) de vouloir approuver cette demande de réservation ?
            L'auteur(e) recevra un message de confirmation.
        </ConfirmDialog>
        <ConfirmDialog
            title={"Annuler une demande de réservation"}
            acceptText={"Annuler la demande"}
            visibleState={declineDialogState}
            acceptPromise={() => onDecline()}>
            Êtes-vous sûr(e) de vouloir annuler cette demande de réservation ?
            L'auteur(e) recevra un message de confirmation.
        </ConfirmDialog>
        <ApprovePopover
            anchorEl={anchorEl}
            open={approving}
            setOpen={setApproving}
            action={onApprove}/>
    </>
}
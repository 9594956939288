import {Button, Grid, Stack} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";

/**
 * Represents a form that can be submitted.
 * When the form is submitted, the submit button is replaced by a loading button to prevent the user from submitting
 * the form multiple times.
 * Once the submission is complete, the loading button is replaced by the submit button again.
 *
 * @param backButton The button that returns to the previous page.
 * @param showBackButton Whether the back button should be shown.
 * @param submitButton The button that submits the form.
 * @param showSubmitButton Whether the submit button should be shown.
 * @param additionalButtons Additional buttons that can be added to the form.
 * @param disabled Whether the form is disabled.
 * @param readonly Whether the form is readonly.
 * @param children The content of the form.
 */
export default function CustomForm({
    backButton = {
        text: "Retour",
        action: async () => {},
        icon: null
    },
    showBackButton = true,
    submitButton = {
        text: "Valider",
        action: async () => {},
        icon: null
    },
    showSubmitButton = true,
    additionalButtons = [],
    disabled = false,
    readonly = false,
    children,
}) {
    const [mounted, setMounted] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [cancelling, setCancelling] = useState(false);

    useEffect(() => () => setMounted(false), []);

    function onSubmit(e) {
        if (mounted && !disabled) {
            if (submitting) return;
            e.preventDefault();
            submit();
        }
    }

    async function submit() {
        if (mounted && !disabled) {
            setSubmitting(true);
            await submitButton.action()
                .then(() => setSubmitting(false))
                .catch(() => setSubmitting(false));
        }
    }

    async function back() {
        if (mounted && !disabled) {
            setCancelling(true);
            await backButton.action()
                .then(() => setCancelling(false))
                .catch(() => setCancelling(false));
        }
    }

    return (
        <form onSubmit={onSubmit} id={"form"}>
            <Grid container spacing={2}>
                {children}
                <Grid item xs={12} sx={{marginTop: 2}}>
                    <Stack direction={"row-reverse"} spacing={2}>
                        {
                            !readonly && showSubmitButton && <LoadingButton
                                type={"submit"}
                                variant="contained"
                                loading={submitting}
                                disabled={cancelling || disabled}
                                startIcon={submitButton.icon}>{submitButton.text}</LoadingButton>
                        }
                        {
                            showBackButton && <LoadingButton
                                variant="outlined"
                                loading={cancelling}
                                disabled={submitting || disabled}
                                startIcon={backButton.icon}
                                onClick={back}>{backButton.text}</LoadingButton>
                        }
                        {
                            additionalButtons.map((button, index) => (
                                <Button
                                    key={index}
                                    variant="outlined"
                                    disabled={submitting || cancelling || disabled}
                                    onClick={button.action}>{button.text}</Button>
                            ))
                        }
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
}

import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {MenuProps} from "../../BookingUtils";

export default function OrganizationField({organizations, state, setState, review, canEdit, show}) {
    if (!show) {
        return null;
    }

    const menuItems = organizations.map(organization =>
        <MenuItem value={organization.id}
                  key={organization.id}
                  sx={{display: 'flex', flexDirection: 'row'}}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
            >
                <span>{organization.name}</span>
            </Stack>
        </MenuItem>);

    menuItems.unshift(<MenuItem value={0}
                                key={0}
                                sx={{display: 'flex', flexDirection: 'row'}}
    >
        <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
        >
            <span><i>Aucune</i></span>
        </Stack>
    </MenuItem>)

    return <FormControl size={"small"} fullWidth required>
        <InputLabel id={"organizationLabel"}>Fédération</InputLabel>
        <Select
            labelId={"organizationLabel"}
            label={"Fédération"}
            variant="outlined"
            onChange={e => {
                setState(prev => ({...prev, organizationId: e.target.value}));
            }}
            value={state.organizationId}
            inputProps={{readOnly: review && !canEdit}}
            disabled={review && !canEdit}
            MenuProps={MenuProps}
        >
            {menuItems}
        </Select>
    </FormControl>
}
import {Box, Grid,} from "@mui/material";
import BookRoomForm from "./form/BookRoomForm";
import BookCatererForm from "./form/BookCatererForm";
import axios from "axios";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Queries from "../../service/queries";
import {useQueryClient} from "react-query";
import SubmitButton from "../common/SubmitButton";
import {useState} from "react";
import {getCurrentDay} from "./BookingUtils";

export default function BookingBoth() {

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [roomState, setRoomState] = useState({
        startTime: getCurrentDay(3),
        endTime: getCurrentDay(3),
        period: "MORNING",
        name: "",
        room: "",
        roomId: "",
        guests: "",
        gearsId: [],
        gears: [],
        notes: ""
    })
    const [catererState, setCatererState] = useState({
        startTime: new Date(new Date().getTime() + 3 * 86400000),
        endTime: new Date(new Date().getTime() + 3 * 86400000),
        type: "SELF",
        period: "LUNCH",
        guests: "",
        caterer: "",
        catererId: "",
        room: "",
        roomId: "",
        menu: {
            name: "",
            price: 0
        },
        menuId: "",
        name: "Réservation de repas",
        notes: ""
    })

    async function submit() {
        await axios.post("/api/booking/both", {
            'room': roomState,
            'meal': catererState
        }).then(response => {
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
            navigate("/bookings");
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    function onSubmit(e) {
        e.preventDefault();
    }

    return (
        <Box>
            <form onSubmit={onSubmit}>
                <Grid container spacing={{xs: 4}} justifyContent={"center"}>
                    <Grid item md={12} lg={8.5} xl={6}>
                        <BookRoomForm formState={[roomState, setRoomState]} review={false} canEdit={true}/>
                    </Grid>
                    <Grid item md={12} lg={8.5} xl={6}>
                        <BookCatererForm formState={[catererState, setCatererState]} review={false} canEdit={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <SubmitButton
                                variant="contained"
                                size="large"
                                action={submit}
                                type={"submit"}>Valider la demande</SubmitButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}
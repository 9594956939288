import {DataGrid} from "@mui/x-data-grid";
import {Box, Button, Chip, Divider, IconButton, Paper, Stack, Tooltip} from "@mui/material";
import {AddRounded, DeleteForeverRounded, EditRounded, PersonRounded, ShieldRounded} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CustomPagination from "../crud/CustomPagination";
import CustomSearch from "../crud/CustomSearch";
import {useSnackbar} from "notistack";
import LocalStorageService from "../../service/localStorage";
import {buildParams} from "../../utils/pageable";
import moment from "moment";
import ConfirmDialog from "../common/ConfirmDialog";
import {useEffect, useState} from "react";

function getUserRole(user) {
    switch (user.row.role) {
        case "ADMIN":
            return {
                label: "Administrateur",
                color: "error",
                icon: () => <ShieldRounded/>
            };
        case "REFERENT":
            return {
                label: "Référent",
                color: "warning",
                icon: () => <ShieldRounded/>
            };
        default:
            return {
                label: "Utilisateur",
                color: "info",
                icon: () => <PersonRounded/>
            };
    }
}

export default function UsersList() {

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(true);
    const deleteDialogState = useState(false);
    const cascadeDialogState = useState(false);
    const [userId, setUserId] = useState(null);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 25,
        field: "",
        sort: "asc",
        filter: "",
        rows: [],
        rowCount: 0,
        loading: false,
    });



    const columns = [
        {
            field: 'lastName',
            headerName: 'Nom',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'firstName',
            headerName: 'Prénom',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'role',
            headerName: 'Rôle',
            minWidth: 150,
            sortable: false,
            valueGetter: (params) => getUserRole(params),
            renderCell: (params) => {
                const chip = params.formattedValue;
                return (<strong>
                    <Chip color={chip.color} size="small" icon={chip.icon()} label={chip.label}/>
                </strong>)
            }
        },
        {
            field: 'organizationName',
            headerName: 'Fédération',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const name = params.formattedValue;
                if (name === undefined) {
                    return (<i style={{color: "gray"}}>Aucune</i>);
                } else {
                    return name;
                }
            }
        },
        {
            field: 'lastModifiedDate',
            headerName: 'Dernière modification',
            minWidth: 250,
            flex: 1,
            valueGetter: (params) => ({
                date: params.row.lastModifiedAt ?? null,
                name: params.row.lastModifiedBy ?? null
            }),
            renderCell: (params) => {
                return <Stack>
                    <span>{
                        params.value.date && (params.value.name
                            ? params.value.name
                            : "Système")
                    }</span>
                    <span>{
                        params.value.date
                            ? <small>{moment(params.value.date).format("Do MMMM YYYY à HH:mm:ss")}</small>
                            : "Jamais"
                    }</span>
                </Stack>;
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            minWidth: 100,
            maxWidth: 100,
            getActions: (params) => [
                <Tooltip title={"Éditer"}>
                    <IconButton size={"small"} color={"warning"} onClick={() => navigate(`${params.id}/edit`)}>
                        <EditRounded fontSize="small"/>
                    </IconButton>
                </Tooltip>,
                <Tooltip title={"Supprimer"}>
                    <IconButton size={"small"} color={"error"} onClick={() => {
                        setUserId(params.id);
                        deleteDialogState[1](true);
                    }}>
                        <DeleteForeverRounded fontSize="small"/>
                    </IconButton>
                </Tooltip>
            ]
        },
    ];

    async function checkCascade() {
        deleteDialogState[1](false);
        let user = rowsState.rows.find(user => user.id === userId);
        if (user === undefined) {
            enqueueSnackbar("Une erreur inattendue est survenue", {
                style: 'error',
                preventDuplicate: true
            })
            return;
        }
        if (user.role === 'ADMIN' || user.role === 'REFERENT') {
            cascadeDialogState[1](true);
        } else {
            await deleteUser();
        }
    }

    async function deleteUser() {
        if (userId === null) {
            return;
        }
        await axios.delete(`/api/users/${userId}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
            setRefresh(!refresh);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
        setUserId(null);
    }

    useEffect(() => {
        (async () => {
            setRowsState(prev => ({...prev, loading: true}));
            const params = buildParams(rowsState.page, rowsState.pageSize, rowsState.field, rowsState.sort);
            params.append("filter", rowsState.filter);
            const newRows = await axios.get(`/api/users`, {params}).catch(() => {
                enqueueSnackbar("Échec de la récupération des utilisateurs", {
                    variant: "error",
                    preventDuplicate: true
                });
            });

            if (newRows) {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                    rows: newRows.data.items,
                    rowCount: newRows.data.count
                }));
            } else {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                }));
            }
        })();
    }, [refresh, rowsState.page, rowsState.pageSize, rowsState.filter, rowsState.field, rowsState.sort, enqueueSnackbar]);

    return (
        <Paper sx={{height: "100%", width: '100%'}}>
            <DataGrid
                columns={columns}
                pagination
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) => setRowsState(prev => ({...prev, page}))}
                onPageSizeChange={(pageSize) =>
                    setRowsState(prev => ({...prev, pageSize}))
                }
                sortingMode="server"
                onSortModelChange={(sortModel) => {
                    if (sortModel.length > 0) {
                        setRowsState({...rowsState, sort: sortModel[0].sort, field: sortModel[0].field});
                    } else {
                        setRowsState({...rowsState, sort: "asc", field: ""});
                    }
                }}
                disableSelectionOnClick
                components={{
                    Footer: () => {
                        return (<Box>
                            <Divider/>
                            <Stack direction={"row-reverse"} spacing={2} sx={{p: 2, alignItems: "center"}}>
                                <Button variant={"outlined"} color={"success"} startIcon={<AddRounded/>}
                                        onClick={() => navigate("new")}
                                        disabled={LocalStorageService.getRole() !== "ADMIN"}>
                                    Ajouter un utilisateur
                                </Button>
                                <CustomPagination/>
                                <CustomSearch rowsState={rowsState} setRowsState={setRowsState}/>
                            </Stack>
                        </Box>)
                    }
                }}
            />

            <ConfirmDialog
                title={"Supprimer un utilisateur"}
                acceptText={"Supprimer"}
                visibleState={deleteDialogState}
                acceptPromise={() => checkCascade()}>
                Êtes-vous sûr(e) de vouloir supprimer cet utilisateur ?
            </ConfirmDialog>

            <ConfirmDialog
                title={"Transfert de supervision"}
                acceptText={"Supprimer l'utilisateur"}
                visibleState={cascadeDialogState}
                acceptPromise={() => deleteUser()}>
                Vous vous apprêtez à supprimer un compte <b>Référent</b> ou <b>Administrateur</b>.
                Si ce compte est le référent d'un ou plusieurs autres comptes, vous deviendrez leur nouveau
                référent !
            </ConfirmDialog>
        </Paper>
    );
}

import {TextField} from "@mui/material";

export default function EventNameField({state, setState, review, canEdit}) {
    return <TextField
        fullWidth
        label={"Nom de l'événement"}
        size={"small"}
        onChange={e => {
            setState(prev => ({...prev, name: e.target.value}));
        }}
        value={state.name}
        required
        inputProps={{readOnly: review && !canEdit}}
        disabled={review && !canEdit}
    />
}
import {Grid, Paper} from "@mui/material";
import BookingStartMenu from "../booking/BookingStartMenu";

export default function Home() {
    return (
        <Grid container alignItems={"stretch"} justifyContent={"center"} height={"100%"}>
            <Grid item xs={12} lg={9} paddingBottom={3}>
                <Paper sx={{p: 3}}>
                    <h1 style={{marginTop: 0}}>AFUL Réservation</h1>
                    <p>Bienvenue sur la nouvelle application de réservation de salles du complexe de la CGT à
                        Montreuil !</p>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={9} paddingBottom={3}>
                <BookingStartMenu/>
            </Grid>
        </Grid>
    );
}
import {Chip, Divider, Drawer, Icon, List, ListItemButton, ListItemText, Stack, Tooltip} from "@mui/material";
import {
    AccountCircleRounded,
    AssessmentRounded,
    AssignmentIndRounded,
    BrunchDiningRounded,
    CloseRounded,
    ContentPasteRounded,
    EventNoteRounded,
    EventRounded,
    GroupsRounded,
    HomeRounded,
    MeetingRoomRounded,
    ReceiptLongRounded,
    SecurityRounded,
    SettingsRounded,
    SwitchAccountRounded
} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import LocalStorageService from "../../service/localStorage";
import Queries from "../../service/queries";
import {useQuery} from "react-query";
import axios from "axios";
import Footer from "../Footer";
import {useState} from "react";

const ADMIN_COLOR = "error";
const REFERENT_COLOR = "warning";

function ItemList(key, listItem, data) {
    if (listItem.restrictions && !listItem.restrictions?.includes(data.role)) {
        return null;
    }

    function pillTooltipText() {
        if (listItem.pill === undefined) {
            return "";
        }
        if (listItem.pill() > 99) {
            return listItem.pillDescription + ` (${listItem.pill()})`;
        }
        return listItem.pillDescription;
    }

    function truncatedPillLabel() {
        if (listItem.pill === undefined) {
            return "";
        }
        if (listItem.pill() > 99) {
            return "99+";
        }
        return listItem.pill();
    }

    return (
        <ListItemButton
            key={key}
            onClick={() => data.navigate(listItem.path)}
            selected={listItem.selected(data.location.pathname)}
        >
            <Icon sx={{marginRight: 2}}>
                {listItem.icon !== undefined && listItem.icon}
            </Icon>
            <ListItemText primary={listItem.title}/>
            {
                listItem.pill !== undefined && (
                    listItem.pillDescription !== undefined ? (
                        <Tooltip title={pillTooltipText()}>
                            <strong>
                                <Chip sx={{mr: 1, cursor: "pointer"}} size="small" label={truncatedPillLabel()}/>
                            </strong>
                        </Tooltip>
                    ) : (
                        <strong>
                            <Chip sx={{mr: 1, cursor: "pointer"}} size="small" label={truncatedPillLabel()}/>
                        </strong>
                    )
                )
            }
            {
                listItem.restrictions && data.role === "ADMIN" &&
                <Tooltip title={"Vous êtes administrateur"}>
                    <SecurityRounded color={ADMIN_COLOR} fontSize={"small"}/>
                </Tooltip>
            }
            {
                listItem.restrictions && data.role === "REFERENT" &&
                <Tooltip title={"Vous êtes référent"}>
                    <SecurityRounded color={REFERENT_COLOR} fontSize={"small"}/>
                </Tooltip>
            }
        </ListItemButton>
    );
}

export default function NavigationDrawer() {

    const [pendingRequests, setPendingRequests] = useState(0);
    const [pendingBookingRequests, setPendingBookingRequests] = useState(0);

    useQuery(Queries.PENDING_REQUESTS, () => {
        if (["ADMIN", "REFERENT"].includes(LocalStorageService.getRole())) {
            axios.get("/api/requestAccess/pendingCount")
                .then(response => {
                    setPendingRequests(response.data);
                })
                .catch(() => {
                    setPendingRequests(-1);
                });
        } else {
            setPendingRequests(0);
        }
    });
    useQuery(Queries.PENDING_BOOKING_REQUESTS, () => {
        if (["ADMIN", "REFERENT"].includes(LocalStorageService.getRole())) {
            axios.get("/api/booking/pendingCount")
                .then(response => {
                    setPendingBookingRequests(response.data);
                })
                .catch(() => {
                    setPendingBookingRequests(-1);
                });
        } else {
            setPendingBookingRequests(0);
        }
    });

    const ITEMS = [
        [
            {
                title: "Accueil",
                path: "/",
                selected(path) {
                    return path === "/" || path.startsWith("/book/");
                },
                icon: <HomeRounded/>
            },
            {
                title: "Mon profil",
                path: "/me",
                selected(path) {
                    return path === "/me";
                },
                icon: <AccountCircleRounded/>
            },
            {
                title: "Configuration globale",
                restrictions: ["ADMIN"],
                path: "/settings",
                selected(path) {
                    return path === "/settings";
                },
                icon: <SettingsRounded/>
            }
        ],
        [
            {
                title: "Réservations",
                path: "/bookings",
                selected(path) {
                    return path === "/bookings";
                },
                icon: <EventRounded/>
            },
            {
                title: "Demandes de réservation",
                restrictions: ["REFERENT", "ADMIN"],
                path: "/bookingRequests",
                pill() {
                    if (pendingRequests === -1) {
                        return <CloseRounded fontSize={"small"}/>;
                    }
                    return pendingBookingRequests;
                },
                pillDescription: pendingRequests === -1 ?
                    "Nombre de demandes en attente indisponible" :
                    "Demandes en attente",
                selected(path) {
                    return path.startsWith("/bookingRequests");
                },
                icon: <EventNoteRounded/>
            },
            {
                title: "Plannings",
                restrictions: ["ADMIN"],
                path: "/plannings",
                selected(path) {
                    return path.startsWith("/plannings");
                },
                icon: <ContentPasteRounded/>
            },
            {
                title: "Factures",
                restrictions: ["ADMIN"],
                path: "/invoices",
                selected(path) {
                    return path.startsWith("/invoices");
                },
                icon: <ReceiptLongRounded/>
            }
        ],
        [
            {
                title: "Gestion des utilisateurs",
                restrictions: ["REFERENT", "ADMIN"],
                path: "/users",
                selected(path) {
                    return path.startsWith("/users");
                },
                icon: <SwitchAccountRounded/>
            },
            {
                title: "Demandes d'accès",
                restrictions: ["REFERENT", "ADMIN"],
                pill() {
                    if (pendingRequests === -1) {
                        return <CloseRounded fontSize={"small"}/>;
                    }
                    return pendingRequests;
                },
                pillDescription: pendingRequests === -1 ?
                    "Nombre de demandes en attente indisponible" :
                    "Demandes en attente",
                path: "/accessRequests",
                selected(path) {
                    return path.startsWith("/accessRequests");
                },
                icon: <AssignmentIndRounded/>
            }
        ],
        [
            {
                title: "Gestion des salles",
                restrictions: ["ADMIN"],
                path: "/rooms",
                selected(path) {
                    return path.startsWith("/rooms") && path !== "/roomsOccupation";
                },
                icon: <MeetingRoomRounded/>
            },
            {
                title: "Occupation des salles",
                path: "/roomsOccupation",
                selected(path) {
                    return path === "/roomsOccupation";
                },
                icon: <AssessmentRounded/>
            },
            {
                title: "Gestion des traiteurs",
                restrictions: ["REFERENT", "ADMIN"],
                path: "/caterers",
                selected(path) {
                    return path.startsWith("/caterers");
                },
                icon: <BrunchDiningRounded/>
            },
            {
                title: "Gestion des fédérations",
                restrictions: ["ADMIN"],
                path: "/organizations",
                selected(path) {
                    return path.startsWith("/organizations");
                },
                icon: <GroupsRounded/>
            }
        ]
    ]

    const drawerWidth = 330;
    const data = {
        navigate: useNavigate(),
        location: useLocation(),
        role: LocalStorageService.getRole()
    };

    return (
        <Stack>
            <Drawer variant="permanent" sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', top: "auto", position: "absolute"}
            }}>
                {ITEMS.filter((list) => list.filter((item) => {
                    return !item.restrictions || item.restrictions?.includes(data.role)
                }).length > 0).map((list, i) => {
                    return (<div key={i}>
                        {i > 0 && <Divider/>}
                        <List>
                            {list.map((item, j) => ItemList(`${i}.${j}`, item, data))}
                        </List>
                    </div>)
                })}
                <Footer/>
            </Drawer>
        </Stack>
    )
}

import {Box, Link, Paper, Stack} from "@mui/material";
import {ReactComponent as CantorLogo} from "../img/Cantor-logo-rvb-c-06.svg";

export default function Footer() {
    return (
        <Paper square elevation={5} sx={{zIndex: 5000, p: 1, marginTop: "auto"}}>
            <Stack alignItems={"center"} direction={"column"}>
                <Box height={32} width={32}>
                    <a href="https://cantor.fr"><CantorLogo/></a>
                </Box>
                <small>Version {process.env.REACT_APP_VERSION}</small>
                <small>
                    <Link href="https://cantor.fr" color="inherit" underline="none">Cantor</Link> &copy; {
                    new Date().getFullYear()}, Tous droits réservés.
                </small>
            </Stack>
        </Paper>
    );
}

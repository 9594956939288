import {TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {isAdmin} from "../../BookingUtils";

export default function StartDateField({review, state, setState}) {
    return <DesktopDatePicker
        label="Début"
        inputFormat="DD/MM/yyyy"
        value={state.startTime}
        disablePast={!review && !isAdmin()}
        onChange={(e, input) => {
            if (e != null && e.isValid() && (!input || input.length === 10)) {
                let newStartTime = new Date(e.format("YYYY-MM-DD"));
                let difference = newStartTime - state.startTime;
                setState(prev => ({
                    ...prev,
                    startTime: newStartTime,
                    endTime: new Date(state.endTime.getTime() + difference)
                }));
            }
        }}
        renderInput={(params) => <TextField size={"small"} fullWidth {...params} required/>}
        readOnly={review}
        disabled={review}
    />
}
import {useEffect, useRef} from "react";
import axios from "axios";
import LocalStorageService from "../service/localStorage";
import {useNavigate} from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function setupRequestInterceptor() {
    return axios.interceptors.request.use(config => {
        const accessToken = LocalStorageService.getSessionToken();

        if (accessToken && config.url !== "/api/auth/login") {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    });
}

function setupResponseInterceptor(navigate) {
    let isRefreshing = false;

    return axios.interceptors.response.use(response => response, error => {
        if (error.response.status === 401) {
            const originalRequest = error.config;

            if (originalRequest.url === "/api/auth/login") {
                return Promise.reject(error);
            }

            if (originalRequest.url === "/api/auth/refresh") {
                LocalStorageService.clearSession();
                navigate("/login");
                return Promise.reject(error);
            }

            if (!originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(resolve => {
                        setTimeout(() => {
                            resolve(axios(originalRequest));
                        }, 500);
                    })
                }

                originalRequest._retry = true;
                isRefreshing = true;
                let data = {
                    sessionToken: LocalStorageService.getSessionToken(),
                    refreshToken: LocalStorageService.getRefreshToken()
                }
                if (data.refreshToken === null) {
                    return Promise.reject(error).finally(() => isRefreshing = false);
                }
                return axios.patch('/api/auth/refresh', data).then(response => {
                    if (response.status === 200) {
                        LocalStorageService.setTokens(response.data);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.sessionToken;
                        return axios(originalRequest);
                    }
                }).finally(() => isRefreshing = false);
            }
        }
        throw error;
    });
}

export function InjectInterceptors() {
    const navigate = useRef(useNavigate());
    useEffect(() => {
        const requestInterceptor = setupRequestInterceptor();
        const responseInterceptor = setupResponseInterceptor(navigate);
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        }
    }, []);
    return null;
}

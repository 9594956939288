import {TextField} from "@mui/material";

export default function ParticipantsField({state, setState, onChange, review, label}) {
    return <TextField
        fullWidth
        label={label}
        type="number"
        size={"small"}
        onChange={e => {
            setState(prev => ({...prev, guests: e.target.value}))
            onChange(e.target.value)
        }}
        value={state.guests}
        required
        inputProps={{readOnly: review}}
        disabled={review}
    />
}
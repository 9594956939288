import {
    DataGrid,
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    GridToolbarContainer,
    GridToolbarExport
} from "@mui/x-data-grid";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tooltip
} from "@mui/material";
import {
    AddRounded,
    CheckRounded,
    CloseRounded,
    ExpandMoreRounded,
    GroupsRounded,
    LocalDiningRounded,
    VisibilityRounded,
    WarningRounded
} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CustomPagination from "../crud/CustomPagination";
import moment from "moment/moment";
import {useSnackbar} from "notistack";
import {useQuery, useQueryClient} from "react-query";
import Queries from "../../service/queries";
import {buildParamsMultipleSorts} from "../../utils/pageable";
import ConfirmDialog from "../common/ConfirmDialog";
import ApprovePopover from "./ApprovePopover";
import {makeStyles} from "@mui/styles";
import {useCallback, useEffect, useState} from "react";
import LocalStorageService from "../../service/localStorage";
import SessionStorageService from "../../service/sessionStorage";

const useStyle = makeStyles((theme) => ({
    meetingBooking: {
        backgroundColor: theme.palette.success.main + "40", // + "40" for transparency at 25%
    },
    mealBooking: {
        backgroundColor: theme.palette.info.main + "40", // + "40" for transparency at 25%
    },
    unknownBooking: {
        backgroundColor: theme.palette.error.main + "40", // + "40" for transparency at 25%
    }
}));

const cellColor = (style) => (params) => {
    switch (params.row.bookingType) {
        case "ROOM":
            return style.meetingBooking;
        case "MEAL":
            return style.mealBooking;
        default:
            return style.unknownBooking;
    }
}

const getFetchUrl = (review, userOnly, isAdmin, organizationId, onlyRelevantBookings) => {
    let url = "";
    if (review) {
        url = isAdmin && organizationId ? "/api/booking/pending?organizationId=" + organizationId : "/api/booking/pending";
    } else if (userOnly) {
        url = "/api/booking/my";
    } else if (isAdmin) {
        url = organizationId ? "/api/booking/all?organizationId=" + organizationId : "/api/booking/all";
    } else {
        url = "/api/booking/my?includeOrganization=true";
    }
    if (onlyRelevantBookings) {
        url += url.includes("?") ? "&showOnlyRelevantBookings=true" : "?showOnlyRelevantBookings=true";
    }
    return url;
}

function mapOperatorValue(operatorValue) {
    switch (operatorValue) {
        case '=':
            return 'equals';
        case '<':
            return 'lessThan';
        case '>':
            return 'greaterThan';
        case '>=':
            return 'greaterThanOrEquals';
        case '<=':
            return 'lessThanOrEquals';
        case '!=':
            return 'not';
        default:
            return operatorValue;
    }
}

function sortModelToSorts(sortModel) {
    let sorts = []
    if (sortModel === null) return [{field: 'startTime', direction: 'desc'}];
    if (sortModel.length > 0) {
        switch (sortModel[0].field) {
            case 'authorName':
                sorts = [
                    {field: 'authorLastName', direction: sortModel[0].sort},
                    {field: 'authorFirstName', direction: sortModel[0].sort},
                ];
                break;
            default:
                sorts = [{field: sortModel[0].field, direction: sortModel[0].sort}];
                break;
        }
    } else {
        sorts = [{field: 'startTime', direction: 'desc'}]
    }
    return sorts;
}

function filterModelToFilters(filterModel) {
    const filters = [];
    if (filterModel === null) return [];
    for (const {columnField, operatorValue, value} of filterModel.items) {
        if (value === undefined) {
            if (operatorValue === 'isEmpty' || operatorValue === 'isNotEmpty') {
                filters.push({
                    field: columnField + '.' + operatorValue,
                    value: true
                })
            }
            continue;
        }

        filters.push({
            field: columnField + '.' + mapOperatorValue(operatorValue),
            value: value
        })
    }
    return filters;
}

export default function BookingsList({review = false}) {

    const {enqueueSnackbar} = useSnackbar();
    const style = useStyle();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const cancelRequestDialogState = useState(false);
    const cancelDialogState = useState(false);
    const declineDialogState = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [approving, setApproving] = useState(false);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 100,
        sorts: sortModelToSorts(SessionStorageService.getSortModel()),
        filters: filterModelToFilters(SessionStorageService.getFilterModel()),
        rows: [],
        rowCount: 0,
        loading: false,
    });
    const [userOnly, setUserOnly] = useState(false);
    const [relevantBookingsOnly, setRelevantBookingsOnly] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(LocalStorageService.getUserOrganization() || 0);

    const isAdmin = LocalStorageService.getRole().toUpperCase() === 'ADMIN';
    const isReferent = LocalStorageService.getRole().toUpperCase() === 'REFERENT';

    useQuery(Queries.ROOM_BOOKINGS, fetchBookings);
    useEffect(fetchBookings, [review, queryClient, rowsState.filter, rowsState.page,
        rowsState.pageSize, rowsState.sorts, rowsState.filters, enqueueSnackbar, userOnly, isAdmin, isReferent, selectedOrganization, relevantBookingsOnly]);

    useEffect(() => {
        axios.get(`/api/organizations/public`).then(response => {
            setOrganizations(response.data.items.toSorted((a, b) => a.name > b.name ? 1 : -1));
        }).catch(() => {
            enqueueSnackbar("Échec de la récupération des fédérations", {
                variant: "error",
                preventDuplicate: true
            });
        })
    }, [enqueueSnackbar]);

    function fetchBookings() {
        setRowsState(prev => ({...prev, loading: true}));
        const params = buildParamsMultipleSorts(rowsState.page, rowsState.pageSize, rowsState.sorts, rowsState.filters);
        const url = getFetchUrl(review, userOnly, isAdmin, selectedOrganization, relevantBookingsOnly);
        axios.get(url, {params}).then(response => {
            setRowsState(prev => ({
                ...prev,
                loading: false,
                rows: response.data.items,
                rowCount: response.data.count
            }));
        }).catch(() => {
            enqueueSnackbar(!review ? "Échec de la récupération des réservations" :
                "Échec de la récupération des demandes de réservation", {
                variant: "error",
                preventDuplicate: true
            });
            setRowsState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleSortModelChange = useCallback(sortModel => {
        const sorts = sortModelToSorts(sortModel);
        setRowsState({...rowsState, sorts: sorts});
        SessionStorageService.setSortModel(sortModel);
    }, [rowsState]);

    const handleFilterModelChange = useCallback(filterModel => {
        const filters = filterModelToFilters(filterModel);
        setRowsState({...rowsState, filters: filters});
        SessionStorageService.setFilterModel(filterModel);
    }, [rowsState]);

    const columns = [
        {
            field: 'bookingType',
            headerName: 'Type',
            type: 'singleSelect',
            valueOptions: [
                {value: 'ROOM', label: 'Réunion'},
                {value: 'MEAL', label: 'Repas'}
            ],
            filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'isAnyOf'),
            minWidth: 125,
            maxWidth: 125,
            valueFormatter: ({value}) => {
                switch (value) {
                    case 'ROOM':
                        return 'Salle';
                    case 'MEAL':
                        return 'Repas';
                    default:
                        return 'Inconnu';
                }
            },
            renderCell: ({value}) => {
                switch (value) {
                    case 'ROOM':
                        return <Chip variant={"outlined"} color={"success"} size="small"
                                     icon={<GroupsRounded fontSize={"small"}/>} label={"Réunion"}/>
                    case 'MEAL':
                        return <Chip variant={"outlined"} color={"info"} size="small"
                                     icon={<LocalDiningRounded fontSize={"small"}/>} label={"Repas"}/>
                    default:
                        return <Chip variant={"filled"} color={"error"} size="small"
                                     icon={<WarningRounded fontSize={"small"}/>} label={"Inconnu"}/>
                }
            },
            cellClassName: cellColor(style),
        },
        {
            field: 'authorName',
            headerName: 'Auteur',
            type: 'string',
            filterOperators: getGridStringOperators().filter(op => op.value !== 'isEmpty'
                && op.value !== 'isNotEmpty'
                && op.value !== 'isAnyOf'),
            minWidth: 180,
            renderCell: (rowData) => {
                if (rowData.row.authorId === LocalStorageService.getUserId()) {
                    return rowData.row.authorName + ' (moi)';
                }
                return rowData.row.authorName;
            },
            flex: 1,
            cellClassName: cellColor(style),
        },
        {
            field: 'organizationName',
            headerName: 'Fédération',
            type: 'string',
            filterOperators: getGridStringOperators().filter(op => op.value !== 'isAnyOf'),
            minWidth: 150,
            flex: 1,
            cellClassName: cellColor(style),
        },
        {
            field: 'startTime',
            headerName: 'Date',
            type: 'date',
            minWidth: 200,
            filterOperators: getGridDateOperators().filter(op => op.value !== 'isEmpty' && op.value !== 'isNotEmpty'),
            valueGetter: ({value}) => {
                return new Date(value);
            },
            renderCell: ({row}) => {
                let start = new Date(row.startTime);
                let end = new Date(row.endTime);
                if (end.getTime() - start.getTime() > 0) {
                    start = moment(start).format('dddd DD MMMM yyyy');
                    end = moment(end).format('dddd DD MMMM yyyy');
                    return <Stack direction={"column"} spacing={0} alignItems={"center"}>
                        <span style={{marginBottom: "-.5em"}}>{start}</span>
                        <ExpandMoreRounded fontSize={"small"}/>
                        <span style={{marginTop: "-.5em"}}>{end}</span>
                    </Stack>
                } else {
                    start = moment(start).format('dddd DD MMMM yyyy');
                    return `${start}`;
                }
            },
            cellClassName: cellColor(style),
        },
        {
            field: 'endTime',
            headerName: 'Date de fin',
            type: 'date',
            valueGetter: ({value}) => {
                return new Date(value)
            }
        },
        {
            field: 'period',
            headerName: 'Période',
            minWidth: 100,
            maxWidth: 100,
            type: 'singleSelect',
            valueOptions: [
                {value: 'MORNING', label: 'Matin'},
                {value: 'AFTERNOON', label: 'Après-midi'},
                {value: 'EVENING', label: 'Soirée'},
                {value: 'WHOLE_DAY', label: 'Journée'},
                {value: 'BREAKFAST', label: 'Petit déjeuner'},
                {value: 'LUNCH', label: 'Déjeuner'},
                {value: 'DINNER', label: 'Dîner'},
            ],
            filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'isAnyOf'),
            valueFormatter: ({value}) => {
                switch (value) {
                    case 'MORNING':
                        return 'Matin';
                    case 'AFTERNOON':
                        return 'Après-midi';
                    case 'EVENING':
                        return 'Soirée';
                    case 'WHOLE_DAY':
                        return 'Journée';
                    case 'BREAKFAST':
                        return 'Petit déjeuner';
                    case 'LUNCH':
                        return 'Déjeuner';
                    case 'DINNER':
                        return 'Dîner';
                    default:
                        return 'Période inconnue';
                }
            },
            cellClassName: cellColor(style),
        },
        {
            field: 'roomName',
            headerName: 'Salle',
            type: 'string',
            filterOperators: getGridStringOperators().filter(op => op.value !== 'isEmpty'
                && op.value !== 'isNotEmpty'
                && op.value !== 'isAnyOf'),
            minWidth: 180,
            flex: 1,
            renderCell: (rowData) => {
                if (rowData.row.roomDeleted) {
                    return <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Chip label={"Supprimée"} color="error" size={"small"}/>
                        <span>{rowData.value.substring(8)}</span>
                    </Stack>
                }
            },
            cellClassName: cellColor(style),
        },
        {
            field: 'name',
            headerName: 'Objet de la réunion',
            type: 'string',
            filterOperators: getGridStringOperators().filter(op => op.value !== 'isEmpty'
                && op.value !== 'isNotEmpty'
                && op.value !== 'isAnyOf'),
            minWidth: 180,
            flex: 1,
            cellClassName: cellColor(style),
        },
        {
            field: 'guests',
            headerName: 'Participants',
            filterOperators: getGridNumericOperators().filter(op => op.value !== 'isEmpty'
                && op.value !== 'isNotEmpty'
                && op.value !== 'isAnyOf'),
            type: 'number',
            minWidth: 100,
            maxWidth: 100,
            cellClassName: cellColor(style),
        },
        {
            field: 'status',
            headerName: 'Statut',
            type: 'singleSelect',
            valueOptions: [
                {value: 'PENDING', label: 'En attente'},
                {value: 'ACCEPTED', label: 'Acceptée'},
                {value: 'CANCELLED', label: 'Annulée'}
            ],
            filterOperators: getGridSingleSelectOperators().filter(op => op.value !== 'isAnyOf'),
            valueGetter: ({value}) => {
                if (value === 'REJECTED') return 'CANCELLED';
                return value;
            },
            valueFormatter: ({value}) => {
                switch (value) {
                    case "PENDING":
                        return 'En attente';
                    case "ACCEPTED":
                        return 'Acceptée';
                    case "REJECTED":
                        return 'Annulée';
                    case "CANCELLED":
                        return 'Annulée';
                    default:
                        return 'Inconnu';
                }
            },
            renderCell: ({value}) => {
                switch (value) {
                    case "PENDING":
                        return <Chip label="En attente" color="warning" variant="outlined"/>;
                    case "ACCEPTED":
                        return <Chip label="Acceptée" color="success" variant="outlined"/>;
                    case "REJECTED":
                        return <Chip label="Annulée" color="error" variant="outlined"/>;
                    case "CANCELLED":
                        return <Chip label="Annulée" color="error" variant="outlined"/>;
                    default:
                        return <Chip label="Inconnu" variant="filled" color="error"
                                     icon={<WarningRounded fontSize={"small"}/>}/>;
                }
            },
            minWidth: 125,
            maxWidth: 125,
            cellClassName: cellColor(style),
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            minWidth: 120,
            disableExport: true,
            getActions: (params) => {
                const actions = [
                    <Tooltip title={"Relire"}>
                        <IconButton size={"small"} color={"info"} onClick={() => {
                            if (params.row.bookingType === "ROOM") {
                                navigate("room/" + params.row.id);
                            } else {
                                navigate("caterer/" + params.row.id);
                            }
                        }}>
                            <VisibilityRounded fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                ]
                switch (params.row.status) {
                    case "PENDING":
                        if (isAdmin || isReferent) {
                            actions.push(
                                <Tooltip title={"Approuver"}>
                                    <IconButton size={"small"} color={"success"}
                                                onClick={(e) => {
                                                    setSelectedBooking(params.row.id);
                                                    setAnchorEl(e.currentTarget);
                                                    setApproving(true);
                                                }}>
                                        <CheckRounded fontSize="small"/>
                                    </IconButton>
                                </Tooltip>,
                                <Tooltip title={"Annuler"}>
                                    <IconButton size={"small"} color={"error"}
                                                onClick={() => {
                                                    setSelectedBooking(params.row.id);
                                                    declineDialogState[1](true)
                                                }}>
                                        <CloseRounded fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            );
                        } else if (params.row.authorId === LocalStorageService.getUserId()) {
                            actions.push(
                                <Tooltip title={"Annuler"}>
                                    <IconButton size={"small"} color={"error"}
                                                onClick={() => {
                                                    setSelectedBooking(params.row.id);
                                                    cancelRequestDialogState[1](true);
                                                }}>
                                        <CloseRounded fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            );
                        }
                        break;
                    case "ACCEPTED":
                        if (isReferent || isAdmin || userOnly || params.row.authorId === LocalStorageService.getUserId()) {
                            actions.push(
                                <Tooltip title={"Annuler"}>
                                    <IconButton size={"small"} color={"error"}
                                                onClick={() => {
                                                    setSelectedBooking(params.row.id);
                                                    cancelDialogState[1](true);
                                                }}>
                                        <CloseRounded fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            );
                        }
                        break;
                    default:
                        break;
                }
                return actions;
            },
            cellClassName: cellColor(style),
        },
    ];

    async function deleteBooking(id) {
        await axios.delete(`/api/booking/${id}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: false
            })
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
            queryClient.invalidateQueries(Queries.ROOM_BOOKINGS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    async function approveBooking(comments) {
        await axios.post(`/api/booking/approve/${selectedBooking}`, {
            internalNotes: comments
        }).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: false
            })
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
            queryClient.invalidateQueries(Queries.ROOM_BOOKINGS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    async function declineBooking(id) {
        await axios.post(`/api/booking/reject/${id}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: false
            })
            queryClient.invalidateQueries(Queries.PENDING_BOOKING_REQUESTS);
            queryClient.invalidateQueries(Queries.ROOM_BOOKINGS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                preventDuplicate: true,
                variant: response.data.severity.toLowerCase()
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    return (
        <Paper sx={{height: "100%", width: '100%'}}>
            <DataGrid
                columns={columns}
                pagination
                {...rowsState}
                initialState={{
                    sorting: {
                        sortModel: SessionStorageService.getSortModel(),
                    },
                    filter: {
                        filterModel: SessionStorageService.getFilterModel()
                    }
                }}

                paginationMode="server"
                onPageChange={(page) => {
                    setRowsState(prev => ({...prev, page}))
                }}
                onPageSizeChange={(pageSize) => {
                    setRowsState(prev => ({...prev, pageSize}))
                }}

                sortingMode="server"
                onSortModelChange={handleSortModelChange}

                filterMode="server"
                onFilterModelChange={handleFilterModelChange}

                onCellDoubleClick={(params) => {
                    if (params.row.bookingType === "ROOM") {
                        navigate("room/" + params.row.id);
                    } else {
                        navigate("caterer/" + params.row.id);
                    }
                }}

                disableSelectionOnClick
                columnVisibilityModel={{
                    organization: isAdmin,
                    endTime: false
                }}
                components={{
                    Toolbar: () => {
                        return (<GridToolbarContainer>
                            <GridToolbarExport printOptions={{disableToolbarButton: true}}
                                               csvOptions={{allColumns: true}}/>
                        </GridToolbarContainer>);
                    },
                    Footer: () => {
                        return (<Box>
                            <Divider/>
                            <Stack direction={"row-reverse"} spacing={2}
                                   sx={{p: 2, alignItems: "center"}}>
                                <Button variant={"outlined"} color={"success"} startIcon={<AddRounded/>}
                                        onClick={() => navigate("/book")}>
                                    Nouvelle réservation
                                </Button>
                                <CustomPagination/>
                                <FormControlLabel control={
                                    <Checkbox disabled={review} checked={userOnly && !review}
                                              onChange={event => setUserOnly(event.target.checked)}/>
                                } label={"Seulement mes réservations"}/>
                                <FormControlLabel control={
                                    <Checkbox checked={relevantBookingsOnly}
                                              onChange={event => setRelevantBookingsOnly(event.target.checked)}/>
                                } label={"Cacher annulées/passées"}/>

                                <FormControl size={"small"} sx={{width: 250}}
                                             disabled={!isAdmin || (userOnly && !review)}>
                                    <InputLabel id="organizationSelect">Filtrer par fédération</InputLabel>
                                    <Select labelId="organizationSelect" label={"Filtrer par fédération"}
                                            onChange={e => {
                                                setSelectedOrganization(e.target.value)
                                            }}
                                            value={selectedOrganization}>
                                        <MenuItem value={0}><em>Toutes</em></MenuItem>
                                        {organizations.map(ref => (<MenuItem value={ref.id} key={ref.id}>
                                            {ref.name}
                                        </MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Box>)
                    }
                }}
            />

            <ConfirmDialog
                title={"Annuler une réservation"}
                acceptText={"Annuler la réservation"}
                rejectText={"Maintenir"}
                visibleState={cancelDialogState}
                acceptPromise={() => deleteBooking(selectedBooking)}>
                Êtes-vous sûr(e) de vouloir annuler cette réservation ?
                La salle sera de nouveau disponible pour d'autres réservations.
            </ConfirmDialog>

            <ConfirmDialog
                title={"Annuler une demande de réservation"}
                acceptText={"Annuler la demande"}
                rejectText={"Conserver"}
                visibleState={cancelRequestDialogState}
                acceptPromise={() => deleteBooking(selectedBooking)}>
                Êtes-vous sûr(e) de vouloir annuler cette demande de réservation ?
                La salle sera de nouveau disponible pour d'autres réservations.
            </ConfirmDialog>

            <ConfirmDialog
                title={"Annuler une demande de réservation"}
                acceptText={"Annuler la demande"}
                visibleState={declineDialogState}
                acceptPromise={() => declineBooking(selectedBooking)}>
                Êtes-vous sûr(e) de vouloir annuler cette demande de réservation ?
                L'auteur(e) recevra un message de confirmation.
            </ConfirmDialog>

            <ApprovePopover
                anchorEl={anchorEl}
                anchorOrigin={{horizontal: "center", vertical: "center"}}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                open={approving}
                setOpen={setApproving}
                action={approveBooking}/>
        </Paper>
    );
}

import LocalStorage from "./localStorage";

const Roles = function() {
    return {
        isAdmin() {
            return LocalStorage.getRole().toUpperCase() === "ADMIN"
        },
        isReferent() {
            return LocalStorage.getRole().toUpperCase() === "REFERENT"
        },
        isUser() {
            return LocalStorage.getRole().toUpperCase() === "USER"
        }
    }
}

export default Roles();

import {FormControl, InputLabel, MenuItem, Select, Stack, Tooltip} from "@mui/material";
import {WarningRounded} from "@mui/icons-material";
import {MenuProps} from "../../BookingUtils";

export default function CatererSelectField({state, setState, review, caterers}) {
    return <FormControl size={"small"} fullWidth required disabled={state.type !== "CATERER" || review}>
        <InputLabel id="catererSelect">Traiteur</InputLabel>
        <Select
            labelId="catererSelect"
            label={"Traiteur"}
            variant="outlined"
            onChange={e => {
                setState(prev => ({...prev, catererId: e.target.value, menuId: ""}))
            }}
            value={review ? 0 : state.catererId}
            inputProps={{readOnly: review}}
            MenuProps={MenuProps}
        >
            {
                review
                    ? <MenuItem key={"CATERER"} value={0}>{state.caterer}</MenuItem>
                    : caterers.filter(caterer => !caterer.closed).map(caterer => {
                        if (caterer.menus.length === 0 && !review) {
                            return <Tooltip title={"Aucun menu disponible"} key={caterer.id}>
                                <div>
                                    <MenuItem value={caterer.id} disabled>
                                        <Stack direction={"row"} spacing={1}>
                                            <span>{caterer.companyName}</span>
                                            <WarningRounded color={"error"}/>
                                        </Stack>
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        }
                        return <MenuItem value={caterer.id} key={caterer.id}>
                            {caterer.companyName}
                        </MenuItem>
                    })
            }
            {
                !review && caterers.filter(caterer => !caterer.closed).length === 0 &&
                <MenuItem key={"NO_CATERER"} value={0} disabled>
                    <em>Aucune traiteur disponible</em>
                </MenuItem>
            }
        </Select>
    </FormControl>
}
const SessionStorageService = function () {
    return {
        setFilterModel(filterModel) {
            sessionStorage.setItem("filter_model", JSON.stringify(filterModel));
        },
        getFilterModel(filterModel) {
            return JSON.parse(sessionStorage.getItem("filter_model"));
        },
        setSortModel(sortModel) {
            sessionStorage.setItem("sort_model", JSON.stringify(sortModel));
        },
        getSortModel(sortModel) {
            return JSON.parse(sessionStorage.getItem("sort_model"));
        }
    }
}

export default SessionStorageService();

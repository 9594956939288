import {Backdrop, Box, Button, Popover, Stack, TextField} from "@mui/material";
import SubmitButton from "../common/SubmitButton";
import {useEffect, useState} from "react";

/**
 * Popover for adding a comment when approving a booking request
 * @param anchorEl the button that opens the popover
 * @param anchorOrigin the origin of the anchor element the popover is attached to
 * @param transformOrigin the origin of the popover
 * @param open whether the popover is open
 * @param setOpen function to set the open state
 * @param action the action to call when the 'approve' button is clicked
 */
export default function ApprovePopover({
    anchorEl,
    anchorOrigin = {vertical: 'top', horizontal: 'right'},
    transformOrigin = {vertical: 'bottom', horizontal: 'right'},
    open,
    setOpen,
    action,
}) {

    const [mounted, setMounted] = useState(true);
    useEffect(() => () => setMounted(false), []);

    const [internalNotes, setInternalNotes] = useState("");
    const [locked, setLocked] = useState(false);

    function close() {
        if (locked || !mounted) return;
        setOpen(false);
        setInternalNotes("");
    }

    async function callAction() {
        if (mounted) setLocked(true);
        await action(internalNotes);
        if (mounted) {
            setLocked(false);
            close();
        }
    }

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}>
            <Popover
                open={open}
                onClose={close}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}>
                <Box padding={2}>
                    <Stack justifyContent={"right"}>
                        <h3 style={{marginTop: 0, paddingRight: "6em"}}>Ajouter un commentaire et approuver</h3>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Commentaires"
                            variant="outlined"
                            onChange={(e) => setInternalNotes(e.target.value)}
                            value={internalNotes}
                            inputProps={{maxLength: 512}}
                            disabled={locked}
                            helperText={internalNotes.length + "/512"}
                        />
                        <Stack direction={"row-reverse"} spacing={2}>
                            <SubmitButton
                                variant="text"
                                onReset={() => setLocked(false)}
                                action={callAction}>Approuver</SubmitButton>
                            <Button
                                variant="text"
                                disabled={locked}
                                onClick={close}>Annuler</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Popover>
        </Backdrop>
    )
}
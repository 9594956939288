import {
    Box,
    Button,
    Checkbox,
    Divider,
    Fab,
    FormControlLabel,
    Grid,
    Paper,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import {useEffect, useState} from "react";
import {ArrowBackRounded, ArrowForwardRounded, CheckRounded, CloseRounded} from "@mui/icons-material";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {DataGrid} from "@mui/x-data-grid";
import {useSearchParams} from "react-router-dom";
import CustomPagination from "../crud/CustomPagination";
import axios from "axios";
import {makeStyles} from "@mui/styles";
import {useSnackbar} from "notistack";

const useStyle = makeStyles((theme) => ({
    free: {
        backgroundColor: theme.palette.success.light + "80", // + "80" for transparency at 50%
    },
    occupied: {
        backgroundColor: theme.palette.error.light + "80", // + "80" for transparency at 50%
    }
}));

function RoomOccupation() {

    const {enqueueSnackbar} = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();
    const style = useStyle();

    const [date, setDate] = useState(moment().startOf("day"));
    const [displayFreeRooms, setDisplayFreeRooms] = useState(false);
    const [rowsState, setRowsState] = useState({
        rows: [],
        loading: false,
    });

    const columns = [
        {
            field: 'roomName',
            headerName: 'Nom',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'capacity',
            headerName: 'Capacité',
            minWidth: 80,
            maxWidth: 80,
        },
        {
            field: 'breakfastOrMorning',
            align: 'center',
            minWidth: 155,
            sortable: false,
            renderHeader: () => (
                <p style={{fontWeight: 500, textAlign: "center", flexGrow: 1, paddingRight: "5px"}}>
                    Matin/Petit-déjeuner
                </p>
            ),
            renderCell: (params) => {
                return (
                    params.row.breakfastOrMorning
                        ? <Stack direction={"row"} alignItems={"center"} gap={0.5}><CloseRounded/> Occupée</Stack>
                        : <Stack direction={"row"} alignItems={"center"} gap={0.5}><CheckRounded/> Libre</Stack>
                );
            },
            cellClassName: (params) => params.row.breakfastOrMorning ? style.occupied : style.free,
        },
        {
            field: 'lunchOrAfternoon',
            align: 'center',
            minWidth: 155,
            sortable: false,
            renderHeader: () => (
                <p style={{fontWeight: 500, textAlign: "center", flexGrow: 1, paddingRight: "5px"}}>
                    Après-midi/Déjeuner
                </p>
            ),
            renderCell: (params) => {
                return (
                    params.row.lunchOrAfternoon
                        ? <Stack direction={"row"} alignItems={"center"} gap={0.5}><CloseRounded/> Occupée</Stack>
                        : <Stack direction={"row"} alignItems={"center"} gap={0.5}><CheckRounded/> Libre</Stack>
                );
            },
            cellClassName: (params) => params.row.lunchOrAfternoon ? style.occupied : style.free,
        },
        {
            field: 'dinnerOrEvening',
            align: 'center',
            minWidth: 155,
            sortable: false,
            renderHeader: () => (
                <p style={{fontWeight: 500, textAlign: "center", flexGrow: 1, paddingRight: "5px"}}>
                    Soirée/Dîner
                </p>
            ),
            renderCell: (params) => {
                return (
                    params.row.dinnerOrEvening
                        ? <Stack direction={"row"} alignItems={"center"} gap={0.5}><CloseRounded/> Occupée</Stack>
                        : <Stack direction={"row"} alignItems={"center"} gap={0.5}><CheckRounded/> Libre</Stack>
                );
            },
            cellClassName: (params) => params.row.dinnerOrEvening ? style.occupied : style.free,
        }
    ];

    function handleChange(newValue) {
        setDate(newValue);
    }

    function previousDay() {
        setDate(moment(date).subtract(1, 'days'));
    }

    function nextDay() {
        setDate(moment(date).add(1, 'days'));
    }

    useEffect(() => {
        let active = true;

        (async () => {
            setRowsState(prev => ({...prev, loading: true}));
            let params = new URLSearchParams({
                date: date.toISOString(),
                showFreeRooms: displayFreeRooms
            });
            const newRows = await axios.get(`/api/rooms/roomsOccupation`, {params}).catch(() => {
                enqueueSnackbar("Échec de la récupération des salles", {
                    variant: "error",
                    preventDuplicate: true
                });
            });

            if (!active) {
                return;
            }

            if (newRows) {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                    rows: newRows.data.roomsOccupation,
                }));
            } else {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                }));
            }
        })();

        return () => {
            active = false;
        };
    }, [date, displayFreeRooms, enqueueSnackbar]);

    useEffect(() => {
        let date = searchParams.get("date");
        if (date !== null) {
            let dateMoment = moment(date, "YYYY-MM-DD");
            if (dateMoment.isValid()) {
                setDate(dateMoment.startOf("day"));
            } else {
                setDate(moment().startOf("day"));
            }
        }
        let displayFreeRooms = searchParams.get("displayFreeRooms");
        if (displayFreeRooms !== null) {
            setDisplayFreeRooms(displayFreeRooms === "true");
        }
        // I don't want to add 'searchParams' in the dependency array because
        // it would cause an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchParams({
            date: date.format("YYYY-MM-DD"),
            displayFreeRooms: displayFreeRooms,
        })
    }, [date, displayFreeRooms, setSearchParams]);

    return (
        <Grid
            container
            spacing={2}
            wrap={"nowrap"}
            alignItems={"center"}
            height={"100%"}
            paddingX={{xs: 0, xl: 8}}
            paddingY={{xs: 8}}
        >
            <Grid item xs={"auto"}>
                <Tooltip title={"Jour précédent"}>
                    <Fab color={"primary"} onClick={previousDay}>
                        <ArrowBackRounded/>
                    </Fab>
                </Tooltip>
            </Grid>
            <Grid item xs={12} height={"100%"}>
                <Stack spacing={2} height={"100%"}>
                    <Paper sx={{p: 3, pt: 1, height: "100%"}}>
                        <Stack height={"100%"}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <h2>Occupation des salles</h2>
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <Button variant={"text"} onClick={() => setDate(moment().startOf("day"))}>
                                        Aujourd'hui
                                    </Button>
                                    <DatePicker
                                        value={date}
                                        onChange={handleChange}
                                        renderInput={(params) =>
                                            <TextField {...params} size={"small"}/>
                                        }
                                    />
                                </Stack>
                            </Stack>
                            <DataGrid
                                columns={columns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'roomName', sort: 'desc' }],
                                    },
                                }}
                                hideFooterSelectedRowCount={true}
                                hideFooterPagination={true}
                                disableSelectionOnClick={true}
                                disableColumnMenu={true}
                                getRowId={(row) => row.roomId}
                                {...rowsState}
                                components={{
                                    Footer() {
                                        return (<Box>
                                            <Divider/>
                                            <Stack direction={"row-reverse"} spacing={2}
                                                   sx={{p: .5, alignItems: "center"}}>
                                                <CustomPagination/>
                                                <FormControlLabel
                                                    control={<Checkbox checked={displayFreeRooms} onChange={(e) => {
                                                        setDisplayFreeRooms(e.target.checked)
                                                    }}/>} label="Afficher les salles libres"/>
                                            </Stack>
                                        </Box>);
                                    }
                                }}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                                        flexGrow: 1
                                    },
                                }}
                            />
                        </Stack>
                    </Paper>
                </Stack>
            </Grid>
            <Grid item xs={"auto"}>
                <Tooltip title={"Jour suivant"}>
                    <Fab color={"primary"} onClick={nextDay}>
                        <ArrowForwardRounded/>
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

export default RoomOccupation;

import {useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {CloseRounded, SearchRounded} from "@mui/icons-material";

export default function CustomSearch({rowsState, setRowsState}) {

    const [filter, setFilter] = useState(rowsState.filter);

    return (
        <TextField id="filled-basic" label={"Rechercher"} size="small" InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton edge="end" size={"small"} onClick={() => setRowsState(prev => ({...prev, filter: filter}))}>
                    <SearchRounded fontSize={"inherit"}/>
                </IconButton>
                <IconButton edge="end" size={"small"} onClick={() => setRowsState(prev => ({...prev, filter: ""}))}>
                    <CloseRounded fontSize={"inherit"}/>
                </IconButton>
            </InputAdornment>
        }} onChange={e => setFilter(e.target.value)} value={filter} onKeyDown={e => {
            if (e.key === "Enter") {
                setRowsState(prev => ({...prev, filter: filter}))
            }
        }}/>
    )
}

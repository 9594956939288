import {Alert, AlertTitle, Box, Button, Collapse, Container, Grid, Paper, TextField} from "@mui/material";
import axios from "axios";
import LocalStorageService from "../service/localStorage";
import {Navigate, useNavigate} from "react-router-dom";
import {createRef, useState} from "react";

const style = {
    container: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
};

export default function PasswordChanger(props) {

    const navigate = useNavigate();

    const password = createRef();
    const passwordConfirmation = createRef();
    const [errorMessage, setErrorMessage] = useState("");
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [responseMessageTimeout, setResponseMessageTimeout] = useState(0);
    const [responseMessage, setResponseMessage] = useState("");

    function displayError(message, duration) {
        setResponseMessage(message);
        setShowResponseMessage(true);
        setResponseMessageTimeout(setTimeout(() => setShowResponseMessage(false), duration));
    }

    function updatePassword(e) {
        e.preventDefault();

        if (password.current.value !== passwordConfirmation.current.value) {
            setErrorMessage("Les mots de passe ne correspondent pas");
            return;
        }

        axios.patch("/api/users/me/password", {
            oldPassword: null,
            newPassword: password.current.value,
        }).then(_ => {
            refreshSession();
        }).catch(error => {
            let response = error.response;
            clearTimeout(responseMessageTimeout);
            displayError(response.data.message ?? "Une erreur est survenue", 3000);
        });
    }

    function refreshSession() {
        LocalStorageService.setRefreshingSession(true);
        let data = {
            sessionToken: LocalStorageService.getSessionToken(),
            refreshToken: LocalStorageService.getRefreshToken()
        }
        if (data.sessionToken === null) {
            LocalStorageService.setRefreshingSession(false);
            displayError("Impossible de continuer, veuillez vous reconnecter", 5000);
            return;
        }
        axios.patch('/api/auth/refresh', data).then(response => {
            LocalStorageService.setRefreshingSession(false);
            if (response.status === 200) {
                LocalStorageService.setTokens(response.data)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.sessionToken;
                navigate("/");
            }
        }).catch(() => {
            LocalStorageService.setRefreshingSession(false);
        });
    }

    if (!LocalStorageService.getForceChangePassword()) {
        return <Navigate to="/login" state={{from: props.location}}/>;
    }

    return (
        <Container maxWidth={"sm"} sx={{p: 3}} style={style.container}>
            <Collapse direction={"up"} in={showResponseMessage} timeout={400}>
                <Alert severity="error" sx={{marginBottom: 2}}>
                    <AlertTitle>Erreur</AlertTitle>
                    {responseMessage}
                </Alert>
            </Collapse>
            <Paper elevation={3} style={{textAlign: 'center'}}>
                <Box padding={3}>
                    <h1 style={{marginTop: 0}}>Modifiez votre mot de passe</h1>
                    <p>Pour des raisons de sécurité, merci de modifier votre mot de passe avant de continuer.</p>

                    <form onSubmit={updatePassword}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputRef={password}
                                    onChange={_ => setErrorMessage("")}
                                    label="Nouveau mot de passe"
                                    type={"password"}
                                    id={"newPassword"}
                                    variant="outlined"
                                    error={errorMessage !== ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    inputRef={passwordConfirmation}
                                    onChange={_ => setErrorMessage("")}
                                    label="Confirmation du nouveau mot de passe"
                                    type={"password"}
                                    id={"confirmNewPassword"}
                                    variant="outlined"
                                    error={errorMessage !== ""}
                                    helperText={errorMessage}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" type={"submit"}>Continuer</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

import {useEffect, useState} from "react";
import {Alert, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useSnackbar} from "notistack";
import LocalStorage from "../../service/localStorage";
import Roles from "../../service/roles";
import Queries from "../../service/queries";
import {useQueryClient} from "react-query";
import CustomForm from "../common/CustomForm";
import ConfirmDialog from "../common/ConfirmDialog";
import {CheckRounded, CloseRounded} from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};

export default function UserEditor({me = false, draft = false}) {

    const {id} = useParams()
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();

    const [originalOrganization, setOriginalOrganization] = useState(0);
    const [originalRole, setOriginalRole] = useState("");
    const downgradeDialogState = useState(false);
    const organizationDialogState = useState(false);
    const resetPasswordDialogState = useState(false);
    const reactivateUserDialogState = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [referents, setReferents] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        mobile: "",
        fax: "",
        role: "USER",
        organizationId: 0,
        referentId: 0,
        draft: false
    });
    const [passwords, setPasswords] = useState({
        oldPassword: "",
        newPassword: "",
        passwordConfirm: ""
    });

    const editing = id !== undefined;

    if (LocalStorage.getUserId().toString() === id && !me) {
        me = true;
    }

    useEffect(() => {
        let userId = me ? LocalStorage.getUserId() : id;
        if (userId) {
            axios.get("/api/users/" + userId).then(response => {
                setState((prev) => ({...prev, ...response.data}));
                setOriginalOrganization(() => response.data.organizationId);
                setOriginalRole(() => response.data.role);
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
                navigate("/users");
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        }
    }, [me, id, enqueueSnackbar, navigate]);

    useEffect(() => {
        (async () => {
            axios.get(`/api/organizations/all`).then(response => {
                setOrganizations(response.data.items);
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des fédérations", {
                    variant: "error",
                    preventDuplicate: true
                });
            })
        })();
    }, [enqueueSnackbar]);

    useEffect(() => {
        setReferents([]);
        let fetchReferents = (async () => {
            axios.get(`/api/organizations/${state.organizationId}/referents`).then(response => {
                setReferents(response.data.items);
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des utilisateurs référents", {
                    variant: "error",
                    preventDuplicate: true
                });
                setReferents([]);
            })
        })

        if (state.organizationId !== 0) {
            fetchReferents();
        }
    }, [me, enqueueSnackbar, state.organizationId]);

    async function checkDowngrade() {
        resetPasswordDialogState[1](false);
        if ((originalRole === 'ADMIN' || originalRole === 'REFERENT') && state.role === 'USER') {
            downgradeDialogState[1](true);
        } else {
            await checkOrganization();
        }
    }

    async function checkOrganization() {
        downgradeDialogState[1](false);
        if (originalOrganization !== state.organizationId && originalRole !== "USER") {
            organizationDialogState[1](true);
        } else {
            await editUser();
        }
    }

    async function editUser() {
        await axios.put(`/api/users/${id}`, state).then(response => {
            if (draft) {
                navigate("/accessRequests");
                queryClient.invalidateQueries(Queries.PENDING_REQUESTS);
            } else {
                navigate("/users");
            }
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    async function decline() {
        await axios.delete(`/api/requestAccess/${id}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase()
            });
            queryClient.invalidateQueries(Queries.PENDING_REQUESTS);
            navigate("/accessRequests");
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    function reactivate() {
        axios.post("/api/users/reactivate", state).then(response => {
            navigate("/users");
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        });
    }

    async function submit() {
        if (!editing && !me) {
            await axios.post("/api/users", state).then(response => {
                navigate("/users");
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                let response = error.response;
                if (response.status === 428) {
                    reactivateUserDialogState[1](true);
                } else {
                    enqueueSnackbar(response.data.message, {
                        variant: response.data.severity.toLowerCase(),
                        preventDuplicate: true
                    });
                }
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        } else if (me) {
            await axios.put("/api/users/me", state).then(response => {
                if (response.data.payload !== undefined) {
                    LocalStorage.setTokens(response.data.payload);
                    if (editing) {
                        navigate("/users");
                    } else {
                        navigate("/me");
                    }
                } else {
                    LocalStorage.clearSession();
                    navigate("/login");
                }
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(error => {
                let response = error.response;
                enqueueSnackbar(response.data.message, {
                    variant: response.data.severity.toLowerCase(),
                    preventDuplicate: true
                });
            }).catch(() => {
                enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
            });
        } else {
            await checkDowngrade();
        }
    }

    function checkPasswords() {
        if (passwords.newPassword !== passwords.passwordConfirm) {
            setError(true);
            setErrorMessage("Les mots de passe ne correspondent pas");
            return false;
        }
        return true;
    }

    function resetPasswordCheck() {
        setError(false);
        setErrorMessage(null);
    }

    async function updatePassword() {
        if (!checkPasswords()) return;
        let url;
        if (me) {
            url = "/api/users/me/password";
        } else {
            url = `/api/users/${id}/password`;
        }
        await axios.patch(url, {
            oldPassword: passwords.oldPassword,
            newPassword: passwords.newPassword
        }).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
            setPasswords({
                oldPassword: "",
                newPassword: "",
                passwordConfirm: ""
            });
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    async function resetPassword() {
        await axios.post(`/api/users/${id}/resetPassword`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        }).catch(() => {
            enqueueSnackbar("Une erreur inattendue est survenue", {variant: "error"});
        });
    }

    return (
        <Grid
            container
            alignItems={"stretch"}
            justifyContent={"center"}
            height={"100%"}
        >
            <Grid item xs={12} md={12} lg={10} paddingBottom={3}
                  alignItems={"stretch"}>
                <Paper sx={{p: 3}}>
                    <h2 style={{margin: 0}}>{
                        draft ? "Relire une demande d'accès" :
                            me ? "Mon profil" :
                                editing ? "Modifier un utilisateur existant" :
                                    "Créer un compte utilisateur"
                    }</h2>
                    <Grid container spacing={2}>
                        <Grid item gap={2} lg={12} xl={6} alignItems={"center"}
                              alignContent={"flex-start"}>
                            <CustomForm
                                submitButton={{
                                    text: draft ? "Approuver" : (editing || me) ? "Sauvegarder" : "Créer",
                                    action: submit,
                                    icon: draft ? <CheckRounded/> : null
                                }}
                                backButton={{
                                    text: draft ? "Annuler" : "Retour",
                                    action: draft ? decline : async () => navigate("/users"),
                                    icon: draft ? <CloseRounded/> : null
                                }}
                                showBackButton={!me}
                                additionalButtons={draft ? [
                                    {
                                        text: "Retour",
                                        action: () => navigate("/accessRequests"),
                                    }
                                ] : []}>
                                <Grid item xs={12}>
                                    <h3 style={{marginBottom: ".5rem"}}>Informations de l'utilisateur</h3>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Nom"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, lastName: e.target.value}))
                                        }}
                                        value={state.lastName}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Prénom"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, firstName: e.target.value}))
                                        }}
                                        value={state.firstName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="E-mail"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, email: e.target.value}))
                                        }}
                                        value={state.email}
                                        type="email"
                                        disabled={!Roles.isAdmin()}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Téléphone"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, phone: e.target.value}))
                                        }}
                                        value={state.phone}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Mobile"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, mobile: e.target.value}))
                                        }}
                                        value={state.mobile}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Fax"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, fax: e.target.value}))
                                        }}
                                        value={state.fax}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'left'}}>
                                    <FormControl fullWidth>
                                        <InputLabel id="organizationSelect">Fédération</InputLabel>
                                        <Select
                                            required
                                            labelId="organizationSelect"
                                            label={"Fédération"}
                                            variant="outlined"
                                            MenuProps={MenuProps}
                                            onChange={e => {
                                                setState({
                                                    ...state,
                                                    organizationId: e.target.value,
                                                    referentId: 0
                                                })
                                            }}
                                            value={state.organizationId}
                                            disabled={me || state.role === 'ADMIN'}
                                        >
                                            <MenuItem value={0}>Aucune</MenuItem>
                                            {organizations.map(ref => (<MenuItem value={ref.id} key={ref.id}>
                                                {ref.name}
                                            </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: 'left'}}>
                                    <TextField
                                        fullWidth
                                        select
                                        label="Référent"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => ({...prev, referentId: e.target.value}))
                                        }}
                                        value={state.referentId}
                                        disabled={me || state.role !== 'USER'}
                                    >
                                        <MenuItem value={0}>Aucun</MenuItem>
                                        {referents.map(ref => (<MenuItem value={ref.id} key={ref.id}>
                                            {ref.firstName} {ref.lastName}
                                        </MenuItem>))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <h3 style={{marginBottom: ".5rem"}}>Permissions</h3>
                                    <Divider/>
                                </Grid>
                                {!me && <Grid item xs={12}>
                                    <Alert severity={"warning"}>
                                        Un <strong>utilisateur</strong> doit avoir un <strong>référent</strong> !
                                    </Alert>
                                </Grid>}
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                    <TextField
                                        fullWidth
                                        required
                                        select
                                        label="Rôle"
                                        variant="outlined"
                                        onChange={e => {
                                            setState(prev => {
                                                if (e.target.value === 'ADMIN') {
                                                    return {
                                                        ...prev,
                                                        role: e.target.value,
                                                        organizationId: 0,
                                                        referentId: 0
                                                    }
                                                } else if (e.target.value === 'REFERENT') {
                                                    return {...prev, role: e.target.value, referentId: 0}
                                                }
                                                return {...prev, role: e.target.value}
                                            })
                                        }}
                                        value={state.role}
                                        disabled={me}
                                    >
                                        <MenuItem value={"ADMIN"} disabled={!Roles.isAdmin()}>
                                            Administrateur
                                        </MenuItem>
                                        <MenuItem value={"REFERENT"}>Référent</MenuItem>
                                        <MenuItem value={"USER"}>Utilisateur</MenuItem>
                                    </TextField>
                                </Grid>
                            </CustomForm>
                        </Grid>

                        <Grid item gap={2} lg={12} xl={6} alignItems={"center"}
                              alignContent={"flex-start"}>

                            <CustomForm
                                submitButton={{
                                    text: "Mettre à jour le mot de passe",
                                    action: updatePassword,
                                }}
                                backButton={{
                                    text: "Réinitialiser le mot de passe",
                                    action: async () => resetPasswordDialogState[1](true),
                                }}
                                showBackButton={!me}
                                disabled={draft || (!editing && !me)}>
                                <Grid item xs={12}>
                                    <h3 style={{marginBottom: ".5rem"}}>Mot de passe</h3>
                                    <Divider/>
                                </Grid>
                                {
                                    ((!editing && !me) || draft) &&
                                    <Grid item xs={12}>
                                        <Alert severity={"info"} variant={"filled"}>
                                            Un mot de passe provisoire sera envoyé par mail au nouvel utilisateur.
                                            Il sera invité à le changer dès sa première connexion.
                                        </Alert>
                                    </Grid>
                                }
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                    <TextField
                                        fullWidth
                                        label="Mot de passe actuel"
                                        type="password"
                                        variant="outlined"
                                        disabled={!me}
                                        value={passwords.oldPassword}
                                        onChange={e => {
                                            resetPasswordCheck()
                                            setPasswords({...passwords, oldPassword: e.target.value})
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                    <TextField
                                        error={error}
                                        fullWidth
                                        label="Nouveau mot de passe"
                                        type="password"
                                        variant="outlined"
                                        disabled={draft || (!editing && !me)}
                                        value={passwords.newPassword}
                                        onChange={e => {
                                            resetPasswordCheck()
                                            setPasswords({...passwords, newPassword: e.target.value})
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                    <TextField
                                        error={error}
                                        helperText={errorMessage}
                                        fullWidth
                                        label="Confirmer le nouveau mot de passe"
                                        type="password"
                                        variant="outlined"
                                        disabled={draft || (!editing && !me)}
                                        value={passwords.passwordConfirm}
                                        onChange={e => {
                                            resetPasswordCheck()
                                            setPasswords({...passwords, passwordConfirm: e.target.value})
                                        }}
                                    />
                                </Grid>
                            </CustomForm>
                        </Grid>
                    </Grid>

                    <ConfirmDialog
                        title={"Transfert de supervision"}
                        acceptText={"Modifier le rôle"}
                        visibleState={downgradeDialogState}
                        acceptPromise={() => editUser()}>
                        Vous vous apprêtez à modifier le rôle de ce compte en <b>Utilisateur</b>.
                        Si ce compte est le référent d'un ou plusieurs autres comptes, vous deviendrez leur
                        nouveau référent !
                    </ConfirmDialog>

                    <ConfirmDialog
                        title={"Transfert de supervision"}
                        acceptText={"Modifier la fédération"}
                        visibleState={organizationDialogState}
                        acceptPromise={() => editUser()}>
                        Vous vous apprêtez à modifier la fédération à laquelle ce compte est rattaché.
                        Si ce compte est le référent d'un ou plusieurs autres comptes dans sa fédération
                        actuelle, vous deviendrez leur nouveau référent !
                    </ConfirmDialog>

                    <ConfirmDialog
                        title={"Réinitialiser le mot de passe"}
                        acceptText={"Réinitialiser"}
                        visibleState={resetPasswordDialogState}
                        acceptPromise={() => resetPassword()}>
                        Vous vous apprêtez à réinitialiser le mot de passe de ce compte. Un nouveau mot de
                        passe sera généré et envoyé à l'adresse mail associée à ce compte.
                    </ConfirmDialog>

                    <ConfirmDialog
                        title={"Réactiver un utilisateur"}
                        acceptText={"Réactiver"}
                        visibleState={reactivateUserDialogState}
                        acceptPromise={() => reactivate()}>
                        L'adresse e-mail que vous avez saisie est déjà utilisée par un compte désactivé.
                        Souhaitez-vous réactiver ce compte ?
                    </ConfirmDialog>
                </Paper>
            </Grid>
        </Grid>
    );
}
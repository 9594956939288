import jwt_decode from "jwt-decode";

const LocalStorageService = function () {
    return {
        setTokens(tokens) {
            localStorage.setItem("session_token", tokens.sessionToken);
            localStorage.setItem("refresh_token", tokens.refreshToken);
        },
        getSessionToken() {
            return localStorage.getItem("session_token");
        },
        getRefreshToken() {
            return localStorage.getItem("refresh_token");
        },
        clearSession() {
            localStorage.removeItem("session_token");
            localStorage.removeItem("refresh_token");
        },
        getDisplayName() {
            try {
                return jwt_decode(this.getSessionToken()).displayName;
            } catch (e) {
                return undefined;
            }
        },
        getRole() {
            try {
                return jwt_decode(this.getSessionToken()).role;
            } catch (e) {
                return undefined;
            }
        },
        getUserId() {
            try {
                return jwt_decode(this.getSessionToken()).id;
            } catch (e) {
                return undefined;
            }
        },
        getUserOrganization() {
            try {
                return jwt_decode(this.getSessionToken()).organizationId;
            } catch (e) {
                return undefined;
            }
        },
        getForceChangePassword() {
            try {
                return jwt_decode(this.getSessionToken()).forceChangePassword;
            } catch (e) {
                return undefined;
            }
        },
        setRefreshingSession(refreshingSession) {
            localStorage.setItem("refreshing_session", refreshingSession);
        },
        isRefreshingSession() {
            return localStorage.getItem("refreshing_session") === "true";
        }
    }
}

export default LocalStorageService();

import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";

import {MenuProps} from "../../BookingUtils"

export default function GearField({roomGears, state, setState, review, canEdit}) {

    if (review && !canEdit) {
        return <TextField
            fullWidth
            label="Équipement"
            value={state.gears.map(gear => gear.name).join(", ")}
            inputProps={{readOnly: review}}
            disabled={review}
        />
    }

    return <FormControl size={"small"} fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">Équipement</InputLabel>
        <Select
            multiple
            labelId="demo-multiple-checkbox-label"
            onChange={e => {
                if (review) {
                    // Need to update the price, but we don't know the room price itself, so need to do a bit of math
                    const previousGearPrice = state.availableGears
                        .filter(gear => state.gearsId.indexOf(gear.id) > -1)
                        .map(gear => gear.price)
                        .reduce((acc, val) => acc + val, 0);
                    const currentGearPrice = state.availableGears
                        .filter(gear => e.target.value.indexOf(gear.id) > -1)
                        .map(gear => gear.price)
                        .reduce((acc, val) => acc + val, 0);
                    setState(prev => ({
                        ...prev,
                        gearsId: e.target.value,
                        price: state.price - previousGearPrice + currentGearPrice
                    }));
                } else {
                    setState(prev => ({
                        ...prev,
                        gearsId: e.target.value
                    }));
                }
            }}
            value={state.gearsId}
            input={<OutlinedInput label="Équipement"/>}
            renderValue={(selected) => {
                return selected.map(id => roomGears.filter(gear => gear.id === id))
                    .flat()
                    .map(gear => gear.name).join(", ")
            }}
            MenuProps={MenuProps}
        >
            {
                roomGears.map(({id, name, price}) => {
                    return (
                        <MenuItem size={"small"} key={id} value={id}>
                            <Checkbox size={"small"} checked={state.gearsId.indexOf(id) > -1}/>
                            <ListItemText primary={name}
                                          secondary={Intl.NumberFormat('fr-FR', {
                                              style: 'currency',
                                              currency: 'EUR'
                                          }).format(price)}/>
                        </MenuItem>)
                })
            }
            {
                roomGears.length === 0 &&
                <MenuItem key={0} disabled><em>Aucun équipement disponible</em>
                </MenuItem>
            }
        </Select>
    </FormControl>
}
import Header from "./Header";
import background from '../img/1_2.jpg';
import {Box} from "@mui/material";
import {SnackbarProvider} from "notistack";
import '../css/Main.css';

const style = {
    mainContent: {
        flexGrow: 1,
        height: 0,
        position: 'relative'
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: 'url(' + background + ')',
        filter: 'grayscale(100%) blur(7px) opacity(50%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: -1
    }
}

export default function Main(props) {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Header/>
            <div style={style.mainContent}>
                <SnackbarProvider preventDuplicate>
                    <Box sx={{display: "flex", height: "100%", boxSizing: "border-box", overflowY: "clip"}}>
                        <Box component="main" sx={{flexGrow: 1, overflowY: "auto"}}>
                            {props.children}
                        </Box>
                    </Box>
                </SnackbarProvider>
            </div>
            <div style={style.bg}/>
        </div>
    );
}

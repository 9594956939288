import {Alert, AlertTitle, Box, Grid, Paper} from "@mui/material";
import axios from "axios";
import {useSnackbar} from "notistack";
import {useEffect, useMemo, useState} from "react";
import StartDateField from "./fields/StartDateField";
import EndDateField from "./fields/EndDateField";
import EventNameField from "./fields/EventNameField";
import RoomSelectField from "./fields/RoomSelectField";
import PriceIndicatorField from "./fields/PriceIndicatorField";
import DescriptionField from "./fields/DescriptionField";
import CatererTypeField from "./fields/CatererTypeField";
import ParticipantsField from "./fields/ParticipantsField";
import CatererPeriodField from "./fields/CatererPeriodField";
import CatererSelectField from "./fields/CatererSelectField";
import CatererMenuSelectField from "./fields/CatererMenuSelectField";
import OrganizationField from "./fields/OrganizationField";
import {isAdmin} from "../BookingUtils";
import StatusIndicatorField from "./fields/StatusIndicatorField";
import InternalNotesField from "./fields/InternalNotesField";

function computePrice(room, state) {
    if (!room) {
        return 0;
    }
    const days = Math.round((state.endTime - state.startTime) / (1000 * 60 * 60 * 24)) + 1;
    if (days < 0) {
        return 0
    }
    switch (state.period) {
        case "BREAKFAST":
            return room.morningPrice * days;
        case "LUNCH":
            return room.afternoonPrice * days;
        case "DINNER":
            return room.eveningPrice * days;
        default:
            return 0;
    }
}

export default function BookCatererForm({formState, review, canEdit}) {

    const {enqueueSnackbar} = useSnackbar();

    const [state, setState] = formState;
    const [rooms, setRooms] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState(rooms);
    const [caterers, setCaterers] = useState([]);
    const catererMenus = useMemo(() => {
        return caterers.filter(caterer => caterer.id === state.catererId).flatMap(caterer => caterer.menus)
    }, [state.catererId, caterers]);
    const [organizations, setOrganizations] = useState([]);

    useEffect(() => {
        if (!review) {
            setState(prev => ({...prev, price: computePrice(rooms.find(room => room.id === prev.roomId), prev)}));
        }
    }, [state.roomId, state.period, state.startTime, state.endTime, review, rooms, setState])

    useEffect(() => {
        if (!review) {
            axios.get('/api/rooms/caterer', {
                params: {
                    startTime: state.startTime.toISOString(),
                    endTime: state.endTime.toISOString(),
                    period: state.period
                }
            }).then(response => {
                if (!response.data.items.map(room => room.id).includes(state.roomId)) {
                    setState(prev => ({...prev, roomId: ""}));
                }
                setRooms(response.data.items.sort((a, b) => a.name > b.name ? 1 : -1));
                setFilteredRooms(response.data.items.sort((a, b) => a.name > b.name ? 1 : -1));
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des salles", {
                    variant: "error",
                    preventDuplicate: true
                });
            });
        }
    }, [enqueueSnackbar, state.startTime, state.endTime, state.period, state.roomId, setState, review]);

    useEffect(() => {
        if (!review) {
            axios.get("/api/caterers/all").then(response => {
                setCaterers(response.data.items);
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des traiteurs", {
                    variant: "error",
                    preventDuplicate: true
                })
            })
        }
    }, [enqueueSnackbar, review])

    useEffect(() => {
        if (isAdmin()) {
            axios.get('/api/organizations/public')
                .then(response => {
                    setOrganizations(response.data.items.toSorted((a, b) => a.name > b.name ? 1 : -1));
                })
                .catch(() => {
                    enqueueSnackbar("Échec de la récupération des fédérations", {
                        variant: "error",
                        preventDuplicate: true
                    });
                });
        }
    }, [enqueueSnackbar])

    function filterRooms(capacity) {
        if (capacity === "") {
            setFilteredRooms(rooms);
            return;
        }
        let newRooms = rooms.filter(room => room.capacity >= capacity)
        setFilteredRooms(newRooms);
        if (!newRooms.map(room => room.id).includes(state.roomId)) {
            setState(prev => ({...prev, roomId: ""}));
        }
    }

    return <Paper>
        <Box padding={3} sx={{textAlign: "center"}}>
            <h2 style={{marginTop: 0}}>{
                review ? "Relire une réservation de repas" : "Réserver un repas"
            }</h2>
            {
                !review && (state.type === "CATERER" || state.type === "SELF") &&
                <Alert severity="warning" style={{textAlign: "left"}}>
                    <AlertTitle>Rappel</AlertTitle>
                    {state.type === "CATERER" &&
                        <span>N'oubliez pas de contacter le traiteur pour valider votre prestation !</span>}
                    {state.type === "SELF" &&
                        <span>Cette réservation ne sera valable que sous réservation auprès du self !</span>}
                </Alert>
            }
            <Grid container spacing={2} sx={{marginTop: 2}}>
                <Grid item xs={12} md={6} style={{textAlign: 'left'}}>
                    <StatusIndicatorField status={state.status} label={"Statut: "} review={review}/>
                </Grid>
                <Grid item xs={12} md={6} style={{textAlign: 'right'}}>
                    <InternalNotesField state={state} review={review}/>
                </Grid>
                <Grid item xs={12}>
                    <OrganizationField organizations={organizations} state={state} setState={setState}
                                       review={review} show={isAdmin()}
                                       canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StartDateField state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EndDateField state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12} md={6} style={{textAlign: 'left'}}>
                    <CatererTypeField state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParticipantsField state={state} setState={setState} review={review} label={"Couverts"}
                                       onChange={filterRooms}/>
                </Grid>
                <Grid item xs={12}>
                    <CatererPeriodField state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12}>
                    <EventNameField state={state} setState={setState} review={review} canEdit={canEdit}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <CatererSelectField caterers={caterers} state={state} setState={setState} review={review}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <RoomSelectField filteredRooms={filteredRooms} state={state} setState={setState}
                                     review={review}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <PriceIndicatorField price={state.price} label={"Prix (salle uniquement, indicatif): "}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <CatererMenuSelectField catererMenus={catererMenus} state={state} setState={setState}
                                            review={review}/>
                </Grid>
                <Grid item xs={12}>
                    <DescriptionField state={state} setState={setState} review={review} canEdit={canEdit}/>
                </Grid>
            </Grid>
        </Box>
    </Paper>
}
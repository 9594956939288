import {DataGrid} from "@mui/x-data-grid";
import {Box, Button, Divider, Paper, Stack} from "@mui/material";
import {CloseRounded, Visibility} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CustomPagination from "../crud/CustomPagination";
import CustomSearch from "../crud/CustomSearch";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import Queries from "../../service/queries";
import ConfirmDialog from "../common/ConfirmDialog";
import {useEffect, useState} from "react";

export default function RequestsList() {

    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(true);
    const rejectDialogState = useState(false);
    const [requestId, setRequestId] = useState(0);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 25,
        filter: "",
        rows: [],
        rowCount: 0,
        loading: false,
    });

    async function deleteUser() {
        await axios.delete(`/api/requestAccess/${requestId}`).then(response => {
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase()
            });
            setRefresh(!refresh);
            queryClient.invalidateQueries(Queries.PENDING_REQUESTS);
        }).catch(error => {
            let response = error.response;
            enqueueSnackbar(response.data.message, {
                variant: response.data.severity.toLowerCase(),
                preventDuplicate: true
            });
        });
        setRequestId(0);
    }

    const columns = [
        {
            field: 'lastName',
            headerName: 'Nom',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'firstName',
            headerName: 'Prénom',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'E-mail',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'phone',
            headerName: 'Téléphone',
            minWidth: 100,
            flex: .5
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            minWidth: 200,
            maxWidth: 200,
            getActions: (params) => [
                <Button
                    size={"small"}
                    startIcon={<Visibility/>}
                    color={"info"}
                    onClick={() => navigate(`${params.id}`)}
                >Relire</Button>,
                <Button
                    size={"small"}
                    startIcon={<CloseRounded/>}
                    color={"error"}
                    onClick={() => {
                        setRequestId(params.id);
                        rejectDialogState[1](true);
                    }}
                >Annuler</Button>
            ]
        },
    ];

    useEffect(() => {
        let active = true;

        (async () => {
            setRowsState(prev => ({...prev, loading: true}));
            const newRows = await axios('/api/requestAccess/pending', {
                params: {
                    page: rowsState.page,
                    size: rowsState.pageSize,
                    filter: rowsState.filter
                }
            }).catch(() => {
                enqueueSnackbar("Échec de la récupération des demandes d'accès", {
                    variant: "error",
                    preventDuplicate: true
                });
            });

            if (!active) {
                return;
            }

            if (newRows) {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                    rows: newRows.data.items,
                    rowCount: newRows.data.count
                }));
            } else {
                setRowsState(prev => ({
                    ...prev,
                    loading: false,
                }));
            }
        })();

        return () => {
            active = false;
        };
    }, [rowsState.page, rowsState.pageSize, rowsState.filter, refresh, enqueueSnackbar]);

    return (
        <Paper sx={{height: "100%", width: '100%'}}>
            <DataGrid
                columns={columns}
                pagination
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) =>
                    setRowsState(prev => ({...prev, page}))
                }
                onPageSizeChange={(pageSize) =>
                    setRowsState(prev => ({...prev, pageSize}))
                }
                onCellDoubleClick={(params) =>
                    navigate(`${params.id}`)
                }
                disableSelectionOnClick
                components={{
                    Footer: () => {
                        return (<Box>
                            <Divider/>
                            <Stack direction={"row-reverse"} spacing={2} sx={{p: 2, alignItems: "center"}}>
                                <CustomPagination/>
                                <CustomSearch rowsState={rowsState} setRowsState={setRowsState}/>
                            </Stack>
                        </Box>)
                    }
                }}
            />
            <ConfirmDialog
                title={"Rejeter une demande d'accès"}
                acceptText={"Rejeter"}
                visibleState={rejectDialogState}
                acceptPromise={() => deleteUser()}
            >
                Êtes-vous sûr(e) de vouloir rejeter cette demande d'accès ?
            </ConfirmDialog>

        </Paper>
    );
}
